import React from "react";
import { menu } from "../utils/List";
import { Link } from "react-router-dom";

const Drawer = (props) => {
  const changePageFunction = (number) => {
    props.setPage(number);
  };

  //Calling the parent/upper props to run its function
  const linkClicked = () => {
    props.isClicked();
  };

  return (
    <div className="drawer">
      {menu.map((element) => (
        <div className="drawer__row" key={element.value}>
          <Link
            className="menubar__SecondaryButton__link"
            to={element.link}
            key={element.value}
            onClick={() => linkClicked()}
          >
            {element.title}
          </Link>
        </div>
      ))}
    </div>
  );
};

export default Drawer;
