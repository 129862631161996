import React, { useEffect } from "react";
import { blogs } from "../utils/BlogsList";
import Title from "../component/BlogsComponents/Title";
import Header3 from "../component/BlogsComponents/Header3";
import Blogimage from "../component/BlogsComponents/Blogimage";
import Listwithoutheaders from "../component/BlogsComponents/Listwithoutheaders";
import Header2 from "../component/BlogsComponents/Header2";
import Blogparagraph from "../component/BlogsComponents/Blogparagraph";
import Listwithheaders from "../component/BlogsComponents/Listwithheaders";
import Footer from "../homepage/Footer";

const Blogpage = (props) => {
  let header = null;
  let flag = true;

  blogs.forEach((blog) => {
    if (props.link === blog.link && flag) {
      header = blog;
      flag = false;
    }
  });

  const fullblog = header;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="blogpage">
        <Title text={fullblog.title} />
        <Header3 text={fullblog.date} align="center" />
        <Blogimage text={fullblog.img} />
        <Header2 text="Introduction" />
        <Blogparagraph text={fullblog.introduction} />

        <Header2 text="Objectives" />
        <Header3 text={fullblog.list1.subtext} align="left" />
        <Listwithoutheaders text={fullblog.list1} />

        <Header2 text={fullblog.list2.title} />
        <Header3 text={fullblog.list2.subtext} align="left" />
        <Listwithheaders text={fullblog.list2} />

        {fullblog.list3 != null ? (
          <div>
            <Header2 text={fullblog.list3.title} />
            <Header3 text={fullblog.list3.subtext} align="left" />
            <Listwithheaders text={fullblog.list3} />
            <Blogparagraph text={fullblog.list3.paragraph} />
          </div>
        ) : null}

        <Header2 text="Conclusion" />
        <Blogparagraph text={fullblog.conclusion} />
      </div>
      <Footer />
    </>
  );
};

export default Blogpage;
