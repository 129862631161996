import React, { useState } from "react";
import Smalllogo from "../../Images/smallLogo.png";
import { Link } from "react-router-dom";

const FLIGHTRegistration = () => {
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    phone: "",
    gender: "", // New field for gender
    aadharCard: "",
    panCard: "",
    hasIndianPassport: null,
    passportNumber: "",
    hasUSVisa: null,
    bloodGroup: "",
    overseasTravel: "",
    institution: "",
    currentSemester: "",
    branch: "",
    aadharDocument: null,
    passportDocument: null,
    mothersName: "",
    fathersName: "",
    mothersOccupation: "",
    fathersOccupation: "",
    mothersContact: "",
    fathersContact: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [isEmailChecked, setIsEmailChecked] = useState(false);
  const [canProceed, setCanProceed] = useState(false);
  const [isRegistrationComplete, setIsRegistrationComplete] = useState(false);

  const [fileNames, setFileNames] = useState({
    passportDocument: "No file chosen",
    aadharDocument: "No file chosen",
  });

  const Branch = [
    "Computer Science Engineering",
    "Information Technology",
    "Mechanical Engineering",
    "Civil Engineering",
    "Electrical Engineering",
    "Electronics and Communication Engineering",
    "Chemical Engineering",
    "Biotechnology",
    "Engineering",
    "Bachelor of Commerce (B.Com)",
    "Bachelor of Business Administration (BBA)",
    "Bachelor of Science (B.Sc)",
    "Bachelor (Any)",
    "Master of Technology (M.Tech)",
    "Master of Science (M.Sc)",
    "Master of Computer Applications (MCA)",
    "Master of Business Administration (MBA)",
    "Master of Commerce (M.Com)",
    "Master of Engineering (M.E)",
    "Masters (Any)",
  ];

  const handleChange = (e) => {
    const { name, value, type, files, checked } = e.target;
    if (type === "file") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0] || null,
      }));
      setFileNames((prevFileNames) => ({
        ...prevFileNames,
        [name]: files[0] ? files[0].name : "No file chosen",
      }));
    } else if (type === "radio") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value === "yes",
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};

    // Gender validation
    if (!formData.gender) {
      newErrors.gender = "Please select a gender";
    }

    // Existing email check logic
    if (!isEmailChecked) {
      newErrors.email = "Please check your email first";
      return newErrors;
    }

    // New validation logic
    Object.entries(formData).forEach(([key, value]) => {
      if (
        key !== "panCard" &&
        key !== "passportNumber" &&
        key !== "passportDocument" &&
        (value === "" || value === null)
      ) {
        newErrors[key] = "This field is required";
      }
    });

    if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email address";
    }

    if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Invalid phone number";
    }

    if (!/^\d{12}$/.test(formData.aadharCard)) {
      newErrors.aadharCard = "Invalid Aadhar Card number (12 digits required)";
    }

    if (
      formData.panCard &&
      !/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(formData.panCard)
    ) {
      newErrors.panCard = "Invalid PAN Card number";
    }

    // if (formData.hasIndianPassport === null) {
    //   newErrors.hasIndianPassport = "Please select an option";
    // }

    // Only validate passport details if the user has an Indian passport
    // Passport validation
    if (formData.hasIndianPassport === null) {
      newErrors.hasIndianPassport = "Please select an option";
    } else if (formData.hasIndianPassport === true) {
      if (!formData.passportNumber) {
        newErrors.passportNumber = "Passport number is required";
      } else if (!/^[A-Z]{1}[0-9]{7}$/.test(formData.passportNumber)) {
        newErrors.passportNumber = "Invalid Passport number";
      }

      if (!formData.passportDocument) {
        newErrors.passportDocument = "Passport document is required";
      }
    }

    if (formData.hasUSVisa === null) {
      newErrors.hasUSVisa = "Please select an option";
    }

    if (!/^([1-9]|10)$/.test(formData.currentSemester)) {
      newErrors.currentSemester = "Invalid Semester";
    }

    if (!/^\d{10}$/.test(formData.mothersContact)) {
      newErrors.mothersContact = "Invalid phone number";
    }

    if (!/^\d{10}$/.test(formData.fathersContact)) {
      newErrors.fathersContact = "Invalid phone number";
    }

    // File validation
    if (!formData.aadharDocument) {
      newErrors.aadharDocument = "Aadhar Card document is required";
    }

    if (formData.hasIndianPassport && !formData.passportDocument) {
      newErrors.passportDocument = "Passport document is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleEmailCheck = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setMessage("");

    try {
      const response = await fetch(
        "https://us-central1-arcticturn1.cloudfunctions.net/checkEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: formData.email }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setIsEmailChecked(true);
      setCanProceed(data.canProceed);
      setMessage(data.message);
    } catch (error) {
      console.error("Error checking email:", error);
      setMessage(`An error occurred: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      setIsSubmitting(true);
      setMessage("");

      const formDataToSend = new FormData();
      Object.entries(formData).forEach(([key, value]) => {
        if (value instanceof File) {
          formDataToSend.append(key, value);
        } else if (typeof value === "boolean") {
          formDataToSend.append(key, value.toString());
        } else {
          formDataToSend.append(key, value);
        }
      });

      try {
        console.log(
          "Form data being sent:",
          Object.fromEntries(formDataToSend)
        );
        const response = await fetch(
          "https://us-central1-arcticturn1.cloudfunctions.net/submitForm?type=registration",
          {
            method: "POST",
            body: formDataToSend,
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setMessage(data.message);
        // Reset form or redirect user as needed
        setFormData({
          email: "",
          name: "",
          phone: "",
          aadharCard: "",
          panCard: "",
          hasIndianPassport: null,
          passportNumber: "",
          hasUSVisa: null,
          bloodGroup: "",
          overseasTravel: "",
          institution: "",
          currentSemester: "",
          branch: "",
          aadharDocument: null,
          passportDocument: null,
          mothersName: "",
          fathersName: "",
          mothersOccupation: "",
          fathersOccupation: "",
          mothersContact: "",
          fathersContact: "",
        });
        setIsRegistrationComplete(true);
      } catch (error) {
        console.error("Error submitting form:", error);
        setMessage(`An error occurred: ${error.message}`);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      console.log("Form has errors:", errors);
      setMessage("Please correct the errors in the form before submitting.");
    }
  };
  const handleLogout = () => {
    // Add your logout logic here
    // For example, clear local storage, reset state, etc.
    setFormData({ email: "" });
    setIsEmailChecked(false);
    setCanProceed(false);
    setIsRegistrationComplete(false);
  };

  if (isRegistrationComplete) {
    return (
      <div className="registration-form-body">
        <div className="registration-form">
          <img
            src={Smalllogo}
            className="flightinsights-wrapper-img"
            alt="Small Logo"
          />
          <h2 className="form-title">
            FLIGHT Mobility 2025
            <br /> Registration Form
          </h2>
          <div className="form-card feedbackCard">
            <h3 className="card-title">Registration Complete</h3>
            <div className="card-content">
              <p className="warning-message__success">
                You have successfully completed your registration.
              </p>
              <div className="button-group">
                <Link
                  to="/Flightregistration"
                  //className="submit-button"
                  // style={{
                  //   textDecoration: "none",
                  //   display: "inline-block",
                  // }}
                >
                  <button onClick={handleLogout} className="submit-button">
                    Log Out
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  // Render function (UI) goes here
  // ...

  return (
    <div className="registration-form-body">
      <form className="registration-form" onSubmit={handleSubmit}>
        <img
          src={Smalllogo}
          className="flightinsights-wrapper-img"
          alt="Small Logo"
        />
        <h2 className="form-title">
          FLIGHT Mobility 2025
          <br /> Registration Form
        </h2>
        {!isEmailChecked || !canProceed ? (
          <div className="form-card feedbackCard">
            <h3 className="card-title">Registration Login</h3>
            <div className="card-content">
              <div className="form-group">
                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                {errors.email && (
                  <span className="error-message">{errors.email}</span>
                )}
              </div>
              {isEmailChecked && !canProceed && message && (
                <span className="warning-message__error">{message}</span>
              )}
              <button
                type="button"
                onClick={handleEmailCheck}
                disabled={isSubmitting}
                className="submit-button"
                style={{ width: "40%" }}
              >
                {isSubmitting ? "Loggin in..." : "Log In"}
              </button>
            </div>
          </div>
        ) : (
          <>
            <div className="form-cards">
              {/* {Object.keys(errors).length > 0 && (
                <div className="error-summary">
                  Please correct the errors in the form before submitting.
                </div>
              )} */}
              <div className="form-card">
                <h3 className="card-title">Personal Information</h3>
                <div className="card-content">
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="name">Full Name (As per passport)*</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                      {errors.name && (
                        <span className="error-message">{errors.name}</span>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="phone">Phone number*</label>
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                      {errors.phone && (
                        <span className="error-message">{errors.phone}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="bloodGroup">Blood Group*</label>
                      <input
                        type="text"
                        id="bloodGroup"
                        name="bloodGroup"
                        value={formData.bloodGroup}
                        onChange={handleChange}
                        required
                      />
                      {errors.bloodGroup && (
                        <span className="error-message">
                          {errors.bloodGroup}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="gender">Gender*</label>
                      <select
                        id="gender"
                        name="gender"
                        value={formData.gender}
                        onChange={handleChange}
                        required
                        className="custom-select"
                      >
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                      {errors.gender && (
                        <span className="error-message">{errors.gender}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="overseasTravel">
                      Have you travelled OVERSEAS? (Destination, Duration etc.
                      or NA)*
                    </label>
                    <textarea
                      id="overseasTravel"
                      name="overseasTravel"
                      value={formData.overseasTravel}
                      onChange={handleChange}
                      required
                    ></textarea>
                    {errors.overseasTravel && (
                      <span className="error-message">
                        {errors.overseasTravel}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-card">
                <h3 className="card-title">Academic Information</h3>
                <div className="card-content">
                  <div className="form-group">
                    <label htmlFor="institution">
                      Name of Institution/University/College*
                    </label>
                    <input
                      type="text"
                      id="institution"
                      name="institution"
                      value={formData.institution}
                      onChange={handleChange}
                      required
                    />
                    {errors.institution && (
                      <span className="error-message">
                        {errors.institution}
                      </span>
                    )}
                  </div>
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="currentSemester">Current Semester*</label>
                      <input
                        type="text"
                        id="currentSemester"
                        name="currentSemester"
                        value={formData.currentSemester}
                        onChange={handleChange}
                        required
                      />
                      {errors.currentSemester && (
                        <span className="error-message">
                          {errors.currentSemester}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="branch">Branch*</label>
                      <select
                        id="branch"
                        name="branch"
                        value={formData.branch}
                        onChange={handleChange}
                        required
                        className="custom-select"
                      >
                        <option value="">Select Branch</option>
                        {Branch.map((branch, index) => (
                          <option key={index} value={branch}>
                            {branch}
                          </option>
                        ))}
                      </select>
                      {errors.branch && (
                        <span className="error-message">{errors.branch}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-card">
                <h3 className="card-title">Your Documents (student)</h3>
                <div className="card-content">
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="aadharCard">Aadhar Card Number*</label>
                      <input
                        type="text"
                        id="aadharCard"
                        name="aadharCard"
                        value={formData.aadharCard}
                        onChange={handleChange}
                        required
                      />
                      {errors.aadharCard && (
                        <span className="error-message">
                          {errors.aadharCard}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="panCard">PAN Card Number</label>
                      <input
                        type="text"
                        id="panCard"
                        name="panCard"
                        value={formData.panCard}
                        onChange={handleChange}
                      />
                      {errors.panCard && (
                        <span className="error-message">{errors.panCard}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group">
                      <label>Do you possess a valid INDIAN passport?*</label>
                      <div className="radio-group">
                        <label>
                          <input
                            type="radio"
                            name="hasIndianPassport"
                            value="yes"
                            checked={formData.hasIndianPassport === true}
                            onChange={handleChange}
                            required
                          />{" "}
                          Yes
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="hasIndianPassport"
                            value="no"
                            checked={formData.hasIndianPassport === false}
                            onChange={handleChange}
                            required
                          />{" "}
                          No
                        </label>
                      </div>
                      {errors.hasIndianPassport && (
                        <span className="error-message">
                          {errors.hasIndianPassport}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label>Do you have a valid US visa?*</label>
                      <div className="radio-group">
                        <label>
                          <input
                            type="radio"
                            name="hasUSVisa"
                            value="yes"
                            checked={formData.hasUSVisa === true}
                            onChange={handleChange}
                            required
                          />{" "}
                          Yes
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="hasUSVisa"
                            value="no"
                            checked={formData.hasUSVisa === false}
                            onChange={handleChange}
                            required
                          />{" "}
                          No
                        </label>
                      </div>
                      {errors.hasUSVisa && (
                        <span className="error-message">
                          {errors.hasUSVisa}
                        </span>
                      )}
                    </div>
                  </div>
                  {formData.hasIndianPassport && (
                    <div className="form-group">
                      <label htmlFor="passportNumber">Passport Number*</label>
                      <input
                        type="text"
                        id="passportNumber"
                        name="passportNumber"
                        value={formData.passportNumber}
                        onChange={handleChange}
                        required
                      />
                      {errors.passportNumber && (
                        <span className="error-message">
                          {errors.passportNumber}
                        </span>
                      )}
                    </div>
                  )}
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="aadharDocument">Upload Adhar Card*</label>
                      <label className="custom-file-upload">
                        <input
                          type="file"
                          id="aadharDocument"
                          name="aadharDocument"
                          onChange={handleChange}
                          accept=".pdf,.jpg,.jpeg,.png"
                        />
                        Choose File
                      </label>
                      <span className="file-name">
                        {fileNames.aadharDocument}
                      </span>
                      {errors.aadharDocument && (
                        <span className="error-message">
                          {errors.aadharDocument}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="passportDocument">Upload Passport*</label>
                      <label className="custom-file-upload">
                        <input
                          type="file"
                          id="passportDocument"
                          name="passportDocument"
                          onChange={handleChange}
                          accept=".pdf,.jpg,.jpeg,.png"
                        />
                        Choose File
                      </label>
                      <span className="file-name">
                        {fileNames.passportDocument}
                      </span>
                      {errors.passportDocument && (
                        <span className="error-message">
                          {errors.passportDocument}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-card">
                <h3 className="card-title">Parents Information</h3>
                <div className="card-content">
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="mothersName">Mother's Full Name*</label>
                      <input
                        type="text"
                        id="mothersName"
                        name="mothersName"
                        value={formData.mothersName}
                        onChange={handleChange}
                        required
                      />
                      {errors.mothersName && (
                        <span className="error-message">
                          {errors.mothersName}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="fathersName">Father's Full Name*</label>
                      <input
                        type="text"
                        id="fathersName"
                        name="fathersName"
                        value={formData.fathersName}
                        onChange={handleChange}
                        required
                      />
                      {errors.fathersName && (
                        <span className="error-message">
                          {errors.fathersName}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="mothersOccupation">
                        Mother's Occupation*
                      </label>
                      <input
                        type="text"
                        id="mothersOccupation"
                        name="mothersOccupation"
                        value={formData.mothersOccupation}
                        onChange={handleChange}
                        required
                      />
                      {errors.mothersOccupation && (
                        <span className="error-message">
                          {errors.mothersOccupation}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="fathersOccupation">
                        Father's Occupation*
                      </label>
                      <input
                        type="text"
                        id="fathersOccupation"
                        name="fathersOccupation"
                        value={formData.fathersOccupation}
                        onChange={handleChange}
                        required
                      />
                      {errors.fathersOccupation && (
                        <span className="error-message">
                          {errors.fathersOccupation}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="mothersContact">
                        Mother's Contact Number*
                      </label>
                      <input
                        type="tel"
                        id="mothersContact"
                        name="mothersContact"
                        value={formData.mothersContact}
                        onChange={handleChange}
                        required
                      />
                      {errors.mothersContact && (
                        <span className="error-message">
                          {errors.mothersContact}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="fathersContact">
                        Father's Contact Number*
                      </label>
                      <input
                        type="tel"
                        id="fathersContact"
                        name="fathersContact"
                        value={formData.fathersContact}
                        onChange={handleChange}
                        required
                      />
                      {errors.fathersContact && (
                        <span className="error-message">
                          {errors.fathersContact}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button
              type="submit"
              className="submit-button"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </>
        )}

        {/* {message && (
          <p
            className={`message ${
              message.includes("error") ? "error" : "success"
            }`}
          >
            {message}
          </p>
        )} */}
      </form>
    </div>
  );
};

export default FLIGHTRegistration;
