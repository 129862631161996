import React, { useEffect, useState } from "react";
import Blogcard from "../component/Blogcard";
import University from "../../Images/University.jpg";
import { blogs } from "../utils/BlogsList";
import { Link } from "react-router-dom";
import SmallLogo from "../../Images/smallLogo.png";
import LinkedinLogo from "../../Images/linked in .png";
import { db } from "../../Firebase/config";

import Group_photos from "../../Images/unlv group photo copy.jpeg";
import Linkedincard from "../component/Linkedincard";
import { firebaseLooper, getTopRecentDates } from "../utils/functions";

const Linkedinwall = () => {
  const [postlist, setPostlist] = useState(null);

  useEffect(() => {
    db.collection("postlist")
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          var res = firebaseLooper(snapshot);
          console.log(res);
          setPostlist(res);
        }
      });
  }, []);
  console.log("postlist: ", postlist);
  return (
    <div className="linkedin-wall">
      <div className="linkedin-wall-section">
        <div className="linkedin-wall-section-logo-container">
          <img className="section-logo" src={LinkedinLogo} />
        </div>
        <div className="sectionname-followbutton-section">
          <h2 class="section-title">Arcticturn Foundation LinkedIn Wall</h2>
          <a
            href="https://www.linkedin.com/company/arcticturnfoundation/"
            target="_blank" rel="noreferrer"
          >
            <button class="linkedin-button"> + Follow on LinkedIn</button>
          </a>
        </div>
        <div class="linkedin-wall-container">
          {postlist &&
            getTopRecentDates(postlist, 3).map((post) => (
              <Linkedincard post={post} />
            ))}
          {/* <div class="linkedin-card">
            <div class="linkedin-card-header">
              <img
                src={SmallLogo}
                alt="Profile Picture"
                class="profile-picture"
              />
              <div class="post-info">
                <h3 class="author-name">Arcticturn Foundation</h3>
                <p class="post-meta">
                  Admin • Posted on <span class="post-date">Aug 17, 2024</span>
                </p>
              </div>
            </div>
            <div class="linkedin-card-body">
              <h1 class="post-headline">
                🏠 Beyond Research: Life Skills & Independence{" "}
              </h1>
              <br />
              <p class="post-content">
                ArcticTurn Foundation students aren't just embarking on a
                research journey at University of Nevada-Las Vegas – they're
                diving into a transformative life experience! 🚀 Their
                comfortable Vegas apartments are more than just accommodation;
                they're incubators for personal growth and life skills
                development. Here's what our students are learning beyond the
                classroom: 1️⃣ Self-reliance: Managing daily life in a new
                country 2️⃣ Time management: Balancing research, chores, and
                exploration 3️⃣ Cohabitation: Navigating life with roommates from
                diverse backgrounds 4️⃣ New skills: From cooking to budgeting in
                a foreign currency 5️⃣ Domestic responsibilities: Tackling
                cleaning and daily chores 6️⃣ Teamwork: Collaborating in close
                quarters – both challenging and rewarding! Their living spaces
                have become vibrant hubs for: Cultural exchange 🌍 Late-night
                study sessions 📚 Forging lifelong friendships 🤝 This holistic
                experience prepares our students not just for academic success,
                but for life as global citizens. We're proud to offer a program
                that nurtures both intellectual and personal growth. Stay tuned
                for more insights into our students'University of Nevada-Las
                Vegas adventure! hashtag#talentmobility hashtag#indiausa
                hashtag#summerinternship hashtag#arcticturnfoudation
                hashtag#study hashtag#education hashtag#research
              </p>
              <div class="post-image">
                <img src={Group_photos} alt="Post Image" />
              </div>
            </div>
            <div class="linkedin-card-footer">
              <a
                href="https://www.linkedin.com/posts/arcticturnfoundation_talentmobility-indiausa-summerinternship-activity-7225825618167230464-cVgA?utm_source=share&utm_medium=member_desktop"
                target="_blank"
                class="linkedin-link"
              >
                View on LinkedIn
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Linkedinwall;
