import React from "react";

const Title = (props) => {
  return (
    <div
      className="titletext"
      style={{ textAlign: props.align, color: props.color }}
    >
      {props.text}
    </div>
  );
};

export default Title;
