import React from "react";

const Blogcard = (props) => {
  const changePageFunction = (number) => {
    props.setPage(number);
  };
  return (
    <>
      {props.tag !== "blog" ? (
        <div className="projectcard">
          <div className="projectcard__img">
            {props.img ? (
              <img src={props.img} className="projectcard__img__cover" />
            ) : null}
          </div>
          <div className="projectcard__info">
            <div className="projectcard__info__domain">{props.domain}</div>
            <div className="projectcard__info__blogheading">{props.title}</div>
            <div className="projectcard__info__collaboration">
              {props.collaboration}
            </div>
            <div className="projectcard__info__blogcredits">
              {props.subtext}
            </div>
          </div>
        </div>
      ) : (
        <div className="blogcard">
          <div className="blogcard__img">
            {props.img ? (
              <img src={props.img} className="blogcard__img__cover" />
            ) : null}
          </div>
          <div className="blogcard__info">
            <div className="blogcard__info__domain">{props.domain}</div>
            <div className="blogcard__info__blogheading">{props.title}</div>
            <div className="blogcard__info__collaboration">
              {props.collaboration}
            </div>
            <div className="blogcard__info__blogcredits">{props.subtext}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default Blogcard;
