import React from "react";

const Onesectionmultiplepara = (props) => {
  return (
    <div className="onesectionmultiplepara">
      <div className="onesectionmultiplepara_title">{props.title}</div>
      {props.para1 && (
        <div className="onesectionmultiplepara_para">{props.para1}</div>
      )}
      {props.para2 && (
        <div className="onesectionmultiplepara_para">{props.para2}</div>
      )}
      {props.para3 && (
        <div className="onesectionmultiplepara_para">{props.para3}</div>
      )}
      {props.para4 && (
        <div className="onesectionmultiplepara_para">{props.para4}</div>
      )}
      {props.para5 && (
        <div className="onesectionmultiplepara_para">{props.para5}</div>
      )}
      {props.para6 && (
        <div className="onesectionmultiplepara_para">{props.para6}</div>
      )}
      {props.para7 && (
        <div className="onesectionmultiplepara_para">{props.para7}</div>
      )}
    </div>
  );
};

export default Onesectionmultiplepara;
