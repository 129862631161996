import React, { useEffect, useState } from "react";
import { db } from "../../../Firebase/config";
import {
  firebaseLooper,
  generateUniqueId,
  replaceSpacesWithDash,
} from "../../utils/functions";

const Editpop = (props) => {
  const [formopen, setFormopen] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [poplist, setPoplist] = useState(null);
  const [mode, setMode] = useState(null);
  const [popid, setPopid] = useState(null);
  const [popdetails, setPopdetails] = useState({
    name: null,
    phone: null,
    domain: null,
    industry: null,
    img: null,
    workexperience: null,
    location: null,
    about: null,
    education1: null,
    status: false,
    visibility: false,
    uid: null,
    link: null,
  });

  const handleChange = (prop) => (event) => {
    setPopdetails({ ...popdetails, [prop]: event.target.value });
  };

  useEffect(() => {
    db.collection("Poplist")
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          var res = firebaseLooper(snapshot);
          console.log(res);
          setPoplist(res);
        }
      });
  }, [formopen]);

  const deletePop = async (e) => {
    var res = await db
      .collection("Poplist")
      .where("uid", "==", popid)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          // Update the document
          db.collection("Poplist")
            .doc(doc.id)
            .delete()
            .then(() => {
              setFeedback(true);
              console.log("Document deleted updated!");
            });
        });
      });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log(popdetails);

    if (
      popdetails.name !== null &&
      popdetails.phone !== null &&
      popdetails.email !== null &&
      popdetails.domain !== null &&
      popdetails.industry !== null &&
      popdetails.img !== null &&
      popdetails.workexperience !== null &&
      popdetails.location !== null &&
      popdetails.education1 !== null
    ) {
      if (mode === "edit") {
        var res = await db
          .collection("Poplist")
          .where("uid", "==", popid)
          .get()
          .then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              // Update the document
              db.collection("Poplist")
                .doc(doc.id)
                .update({
                  name: popdetails.name,
                  phone: popdetails.phone,
                  email: popdetails.email,
                  domain: popdetails.domain,
                  industry: popdetails.industry,
                  img: popdetails.img,
                  workexperience: popdetails.workexperience,
                  location: popdetails.location,
                  education1: popdetails.education1,
                  uid: popdetails.uid,
                  link: popdetails.link,
                  status: popdetails.status,
                  visibility: popdetails.visibility,
                  about: popdetails.about,
                })
                .then(function () {
                  console.log("Document successfully updated!");
                  setFeedback(true);
                  setFormopen(false);
                  setPopdetails({
                    name: null,
                    phone: null,
                    domain: null,
                    industry: null,
                    img: null,
                    workexperience: null,
                    location: null,
                    about: null,
                    education1: null,
                    status: false,
                    visibility: false,
                    uid: null,
                    link: null,
                  });
                })
                .catch(function (error) {
                  console.error("Error updating document: ", error);
                });
            });
          });
      } else {
        var res = await db
          .collection("Poplist")
          .add({
            name: popdetails.name,
            phone: popdetails.phone,
            email: popdetails.email,
            domain: popdetails.domain,
            industry: popdetails.industry,
            img: popdetails.img,
            workexperience: popdetails.workexperience,
            location: popdetails.location,
            education1: popdetails.education1,
            uid: generateUniqueId(),
            link: replaceSpacesWithDash(popdetails.name),
            status: popdetails.status,
            visibility: popdetails.visibility,
            createdAt: new Date(),
            about: popdetails.about,
          })
          .then(() => {
            setFeedback(true);
            setFormopen(false);
            setPopdetails({
              name: null,
              phone: null,
              domain: null,
              industry: null,
              img: null,
              workexperience: null,
              location: null,
              about: null,
              education1: null,
              status: false,
              visibility: false,
              uid: null,
              link: null,
            });
          });
      }
      console.log("Record saved");
    } else {
      alert("Please enter valid details");
    }
  };

  return (
    <div className="editpop">
      {!formopen ? (
        <>
          <div className="editpop__primaryarea">
            <div className="editpop__header">Professor of Pratice List</div>{" "}
            <div
              className="editpop__addbutton"
              onClick={() => {
                setFormopen(true);
              }}
            >
              {" "}
              Add New PoP
            </div>
          </div>
          <div>
            <table>
              <thead>
                <tr>
                  <th>uid</th>
                  <th>Name</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {poplist &&
                  poplist.map((pop) => (
                    <tr>
                      <td>{pop.uid}</td>
                      <td>{pop.name}</td>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setMode("edit");
                          setPopid(pop.uid);
                          setFormopen(true);
                          setPopdetails({
                            name: pop.name,
                            phone: pop.phone,
                            domain: pop.domain,
                            industry: pop.industry,
                            img: pop.img,
                            workexperience: pop.workexperience,
                            location: pop.location,
                            about: pop.about,
                            education1: pop.education1,
                            status: pop.status,
                            visibility: pop.visibility,
                            uid: pop.uid,
                            link: pop.link,
                            email: pop.email,
                          });
                        }}
                      >
                        Edit
                      </td>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() => deletePop()}
                      >
                        Delete
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div className="editpop__formarea">
          <div className="contactsection__contactform-form">
            <div className="editpopcontactformheaders">
              <div className="editpopcontactformheaders__tags">
                <div
                  className="editpopcontactformheaders__tags_tag"
                  onClick={() => {
                    setPopdetails({
                      ...popdetails,
                      status: !popdetails.status,
                    });
                  }}
                >
                  Current Status : {popdetails.status ? "On" : "Off"}
                </div>
                <div
                  className="editpopcontactformheaders__tags_tag"
                  onClick={() => {
                    setPopdetails({
                      ...popdetails,
                      visibility: !popdetails.visibility,
                    });
                  }}
                >
                  Clickable : {popdetails.visibility ? "On" : "Off"}
                </div>
              </div>
              <div
                className="closebuttononfrom"
                onClick={() => {
                  setFormopen(false);
                  setMode(null);
                  setPopdetails({
                    name: null,
                    phone: null,
                    domain: null,
                    industry: null,
                    img: null,
                    workexperience: null,
                    location: null,
                    about: null,
                    education1: null,
                    status: false,
                    visibility: false,
                    uid: null,
                    link: null,
                  });
                }}
              >
                X
              </div>
            </div>
            <form onSubmit={onSubmit}>
              <div className="contactsection__contactform-form-allinput">
                <div className="contactsection__contactform-form-allinput-input">
                  Name:
                  <input
                    required
                    onChange={handleChange("name")}
                    value={popdetails.name}
                    type="text"
                    name="name"
                    className="contactsection__contactform-form-allinput-input-inputfield"
                  />
                </div>

                <div className="contactsection__contactform-form-allinput-input">
                  Phone Number:
                  <input
                    required
                    onChange={handleChange("phone")}
                    value={popdetails.phone}
                    type="number"
                    name="phone"
                    className="contactsection__contactform-form-allinput-input-inputfield"
                  />
                </div>
                <div className="contactsection__contactform-form-allinput-input">
                  Email:
                  <input
                    required
                    onChange={handleChange("email")}
                    value={popdetails.email}
                    type="email"
                    name="email"
                    className="contactsection__contactform-form-allinput-input-inputfield"
                  />
                </div>
                <div className="contactsection__contactform-form-allinput-input">
                  Domain:
                  <input
                    required
                    onChange={handleChange("domain")}
                    value={popdetails.domain}
                    type="text"
                    name="domain"
                    className="contactsection__contactform-form-allinput-input-inputfield"
                  />
                </div>
                <div className="contactsection__contactform-form-allinput-input">
                  Cuurent Job Position:
                  <input
                    required
                    onChange={handleChange("industry")}
                    value={popdetails.industry}
                    type="text"
                    name="industry"
                    className="contactsection__contactform-form-allinput-input-inputfield"
                  />
                </div>
                <div className="contactsection__contactform-form-allinput-input">
                  Image Link:
                  <input
                    required
                    onChange={handleChange("img")}
                    value={popdetails.img}
                    type="text"
                    name="img"
                    className="contactsection__contactform-form-allinput-input-inputfield"
                  />
                </div>
                <div className="contactsection__contactform-form-allinput-input">
                  Work Experience (in Years):
                  <input
                    required
                    onChange={handleChange("workexperience")}
                    value={popdetails.workexperience}
                    type="number"
                    name="workexperience"
                    className="contactsection__contactform-form-allinput-input-inputfield"
                  />
                </div>

                <div className="contactsection__contactform-form-allinput-input">
                  Location:
                  <input
                    required
                    onChange={handleChange("location")}
                    value={popdetails.location}
                    type="text"
                    name="location"
                    className="contactsection__contactform-form-allinput-input-inputfield"
                  />
                </div>
                <div className="contactsection__contactform-form-allinput-input">
                  About PoP:
                  <input
                    required
                    onChange={handleChange("about")}
                    value={popdetails.about}
                    type="text"
                    name="about"
                    className="contactsection__contactform-form-allinput-input-inputfield"
                  />
                </div>
                <div className="contactsection__contactform-form-allinput-input">
                  Education :
                  <input
                    required
                    onChange={handleChange("education1")}
                    value={popdetails.education1}
                    type="text"
                    name="education1"
                    className="contactsection__contactform-form-allinput-input-inputfield"
                  />
                </div>
              </div>
            </form>

            <button
              onClick={onSubmit}
              className="contactsection__contactform-form-button"
            >
              {mode === "edit" ? "Update" : "Submit"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Editpop;
