export const cosmosobjectives = [
  {
    value: 1,
    title: "Talent Mobility",
    text: "The COSMOS Hub within the university, we prioritize talent mobility to foster collaboration and knowledge exchange. Through our White Labeling program, we provide opportunities for students and professionals to work on real-world projects in partnership with organizations. This hands-on experience allows individuals to apply their skills and contribute to sustainable initiatives. Additionally, our Undergraduate Fellowship program offers talented students the chance to engage in research and development projects, working closely with subject matter experts to enhance their academic and practical knowledge.",
  },
  {
    value: 2,
    title: "Incubator",
    text: "Our COSMOS Hub serves as an incubator for projects initiated through COSMOS. We nurture and support these projects, providing guidance and resources to potentially transform them into startup ventures. By leveraging the expertise and network within COSMOS, we create an environment conducive to innovation, entrepreneurship, and sustainable business development.",
  },
  {
    value: 3,
    title: "Workshops for Students and Faculties Driven by PoP",
    text: "Through the COSMOS Hub, we organize workshops specifically designed for students and faculty members. These workshops are led by our esteemed Professors of Practice (PoPs) who share their knowledge, expertise, and practical insights on sustainability-related topics. By engaging with PoPs, participants gain valuable insights, expand their understanding of sustainable development, and enhance their problem-solving skills.",
  },
  {
    value: 4,
    title: "Developing Undergraduates as COSMOS Project Managers",
    text: "At the COSMOS Hub, we aim to develop undergraduate students into competent project managers. Through hands-on training, mentoring, and exposure to real-world projects, we empower students to take on leadership roles within COSMOS initiatives. This opportunity allows them to refine their project management skills, cultivate their professional network, and contribute to the successful execution of sustainability projects.",
  },
  {
    value: 5,
    title:
      "Training and Engagement Workshops on Current Ongoing Projects in COSMOS",
    text: "To ensure effective project implementation, we conduct training and engagement workshops focused on ongoing projects within COSMOS. These workshops provide a platform for stakeholders to stay updated on project developments, understand the project objectives, and actively engage in the project's progress. By fostering collaboration and knowledge sharing, we aim to maximize the impact of our sustainability initiatives.",
  },
  {
    value: 6,
    title: "IP Generation, White Papers, and Research Paper Outcomes",
    text: "As part of the COSMOS Hub, we emphasize the generation of intellectual property (IP) and the production of impactful research outcomes. Our initiatives result in the creation of IP that can be further developed into sustainable solutions. Additionally, we encourage the publication of white papers and research papers that contribute to the global knowledge base in sustainability. By sharing our findings and insights, we aim to inspire and influence the broader academic and professional community.",
  },
];

export const objectives = [
  {
    value: 1,
    rule: [{ rule: 17, title: "Partnership for the goals" }],
    title:
      "Promote interdisciplinary collaboration for sustainable development",
    text: "The center aims to bring together academics, researchers, and professionals from diverse fields such as environmental science, engineering, social sciences, and business to foster interdisciplinary collaboration.",
    // By encouraging the exchange of knowledge and expertise, the center seeks to generate innovative solutions to complex sustainability challenges.
  },
  {
    value: 3,
    rule: [{ rule: 9, title: "Industry, Innovation & Infrastructure" }],
    title: "Facilitate global mobility for stakeholders",
    text: "Recognizing the importance of global collaboration and knowledge exchange, the center aims to facilitate the mobility of stakeholders involved in sustainability efforts. ",
    // This objective involves supporting initiatives that enable professionals, researchers, and experts to travel and engage with partners worldwide. By promoting international networking, the center seeks to enhance cross-cultural understanding, foster collaboration on global sustainability projects, and facilitate the sharing of best practices and innovative solutions across borders.
  },
  {
    value: 2,
    rule: [{ rule: 17, title: "Partnership for the goals" }],
    title: "Establish global partnerships for sustainable development",
    text: "The center aims to establish partnerships with organizations, institutions, and initiatives worldwide that share a commitment to sustainability. By forging these partnerships, the center can leverage collective expertise and resources to tackle global sustainability challenges on a larger scale. Collaborative projects, joint research efforts, and knowledge exchange programs can be established to promote sustainable practices globally.",
  },

  {
    value: 4,
    rule: [{ rule: 9, title: "Industry, Innovation & Infrastructure" }],
    title: "Drive sustainable development initiatives and projects",
    text: "The center is committed to initiating and supporting sustainable development projects that have a positive impact on society and the environment. This objective involves identifying key areas where sustainable practices can be implemented, facilitating research and development of sustainable technologies, and promoting the adoption of best practices among businesses and organizations.",
  },
  {
    value: 5,
    rule: [
      { rule: 4, title: "Quality Education" },
      { rule: 12, title: "Responsible Consumption & Production" },
    ],
    title: "Engage in outreach and education for sustainability",
    text: "The center seeks to engage with society through outreach programs and educational initiatives. This objective involves organizing workshops, conferences, and seminars to raise awareness about sustainability issues and facilitate knowledge sharing.",
    //cutText:" Additionally, the center may collaborate with schools, communities, and non-profit organizations to develop educational materials and initiatives that promote sustainability and empower individuals to take action."
  },
];

export const cosmoshub = [
  {
    value: 1,
    img: null,
    valueflag: true,
    title: "Talent Mobility",
    text: "At the COSMOS Hub within the university, we prioritize talent mobility to foster collaboration and knowledge exchange. Through our White Labeling program, we provide opportunities for students and professionals to work on real-world projects in partnership with organizations.",
  },
  {
    value: 2,
    img: null,
    valueflag: true,
    title: "Developing Undergraduates as COSMOS Project Managers",
    text: "At the COSMOS Hub, we aim to develop undergraduate students into competent project managers. Through hands-on training, mentoring, and exposure to real-world projects, we empower students to take on leadership roles within COSMOS initiatives.",
  },

  {
    value: 3,
    img: null,
    valueflag: true,
    title: "Workshops for Students and Faculties Driven by PoP",
    text: "Through the COSMOS Hub, we organize workshops specifically designed for students and faculty members. These workshops are led by our esteemed Professors of Practice (PoPs) who share their knowledge, expertise, and practical insights on sustainability-related topics.",
  },

  {
    value: 4,
    img: null,
    valueflag: true,
    title: "IP Generation, White Papers, and Research Paper Outcomes:",
    text: "As part of the COSMOS Hub, we emphasize the generation of intellectual property (IP) and the production of impactful research outcomes. Our initiatives result in the creation of IP that can be further developed into sustainable solutions.",
  },
  {
    value: 5,
    img: null,
    valueflag: true,
    title: "Training and Engagement Workshops",
    text: "To ensure effective project implementation, we conduct training and engagement workshops focused on ongoing projects within COSMOS. These workshops provide a platform for stakeholders to stay updated on project developments, understand the project objectives, and actively engage in the project's progress.",
  },
  {
    value: 6,
    img: null,
    valueflag: true,
    title: "Incubator",
    text: "Our COSMOS Hub serves as an incubator for projects initiated through COSMOS. We nurture and support these projects, providing guidance and resources to potentially transform them into startup ventures.",
  },
];

export const whypop = [
  {
    value: 1,
    img: null,
    valueflag: true,
    title: "Bridging the Gap",
    text: "PoPs bridge the gap between academia, enterprise, and research by leveraging their extensive practical experience and academic background. They bring a unique perspective that combines theoretical knowledge with real-world application.",
  },
  {
    value: 2,
    img: null,
    valueflag: true,
    title: "Thought Leadership",
    text: "Our PoPs are thought leaders who actively contribute to the sustainable development discourse. They provide guidance, mentorship, and contribute to cutting-edge research and innovation, ensuring the latest advancements are incorporated into sustainability initiatives.",
  },

  // {
  //   value: 3,
  //   img: null,
  //   valueflag: true,
  //   title: "Collaborative Impact",
  //   text: "By fostering collaboration among PoPs, COSMOS promotes interdisciplinary partnerships and knowledge exchange. This collaborative approach accelerates the development and implementation of sustainable solutions, leading to meaningful and lasting impact.",
  // },

  {
    value: 3,
    img: null,
    valueflag: true,
    title: "Diverse Expertise",
    text: "Our PoPs come from various backgrounds, encompassing academia, industry, and research. This diversity ensures a rich and multidisciplinary approach to solving complex sustainability challenges.",
  },
];
