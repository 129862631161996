import React from "react";
import Editpop from "./Editpop";
import { useState } from "react";
import Editproject from "./Editproject";
import Appliedpop from "./Appliedpop";
import Addlinkedinpost from "./Addlinkedinpost";

const Admin = (props) => {
  const [pwd, setPwd] = useState(null);
  const [password, setPassword] = useState("");
  const [area, setArea] = useState("editpop");
  const handlePasswordChange = (event) => {
    setPwd(event.target.value);
  };
  const handleKeyPress = (event) => {
    if (event.code === "Enter") {
      // Save the password in the state or perform any other actions here
      setPassword(pwd);
    }
  };

  return (
    <div>
      {password !== "Arcticturn@2023" ? (
        <div>
          <div className="dashboardnavbar">ArcticTurn Foundation Dashboard</div>
          <div className="passwordarea">
            <input
              type="password"
              id="password"
              className="password-input"
              placeholder="Enter your password"
              value={pwd}
              onChange={handlePasswordChange}
              onKeyPress={handleKeyPress}
            />
          </div>
        </div>
      ) : (
        <div>
          <div className="dashboardnavbar">ArcticTurn Foundation Dashboard</div>
          <div className="dashboardarea">
            <div className="dashboarddrawer">
              <a
                className="dashboarddrawer__a"
                onClick={() => {
                  setArea("editpop");
                }}
              >
                Edit Pop
              </a>
              <a
                className="dashboarddrawer__a"
                onClick={() => {
                  setArea("editproject");
                }}
              >
                Edit Projects
              </a>
              <a
                className="dashboarddrawer__a"
                onClick={() => {
                  setArea("linkedin");
                }}
              >
                LinkedIn Posts
              </a>
              <a
                className="dashboarddrawer__a"
                onClick={() => {
                  setArea("appliedpop");
                }}
              >
                Applied PoPs
              </a>
              <a
                className="dashboarddrawer__a"
                onClick={() => {
                  setArea("subusers");
                }}
              >
                Subscribed Users
              </a>
            </div>
            <div className="dashboardplaygound">
              {" "}
              {area === "editpop" && <Editpop />}
              {area === "editproject" && <Editproject />}
              {area === "appliedpop" && <Appliedpop />}
              {area === "linkedin" && <Addlinkedinpost />}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Admin;
