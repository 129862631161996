import React from "react";

const Popcard = (props) => {
  return (
    <div>
      <div className="popcard">
        <div className="popcard__img">
          {props.img ? (
            <img src={props.img} className="popcard__img__cover" />
          ) : null}
        </div>
        <div className="popcard__info">
          <div className="popcard__info__blogheading">{props.title}</div>
          <div className="popcard__info__blogcredits">{props.subtext}</div>
        </div>
      </div>
    </div>
  );
};

export default Popcard;
