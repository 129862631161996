import React from "react";

const Listwithoutheaders = (props) => {
  return (
    <div className="bloglist1">
      <ul>
        {props.text.list.map((item) => (
          <li className="" key={item.title}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Listwithoutheaders;
