import React, { useEffect, useState, useRef } from "react";
import Title from "../component/BlogsComponents/Title";
import Blogparagraph from "../component/BlogsComponents/Blogparagraph";
import Popcard from "../component/Popcard";
import Lether from "../../Images/lether.jpg";
import { poplist } from "../utils/poplist";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Multiplepoints from "../component/BlogsComponents/Multiplepoints";
import { whypop } from "../utils/Cosmoshub";
import { db } from "../../Firebase/config";
import emailjs from "@emailjs/browser";

const Pop = (props) => {
  const form = useRef();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [popContactForm, setPopContactForm] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const [feedback, setFeedback] = useState(false);

  const CLIENT_ID =
    "334616661145-02gn7iho9hecokv8n3tdnhk9is2f7e6s.apps.googleusercontent.com";
  const CLIENT_SECRET = "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";
  //const REDIRECT_URL = "https://developers.google.com/oauthplayground";
  const REFRESH_TOKAN =
    "1//04xWVBobJ7x4zCgYIARAAGAQSNwF-L9Ir9W_wM2HTixAB9rWBkxI5dyrs2llpogAzw0O0rsns6zGR7bSc8N1ajvzfLIvhInjFAyw";

  // const oAuth2Client = new google.auth.OAuth2(
  //   CLIENT_ID,
  //   CLIENT_SECRET,
  //   REDIRECT_URL
  // );
  // oAuth2Client.setCredentials({ refresh_token: REFRESH_TOKAN });

  const handleChange = (prop) => (event) => {
    setPopContactForm({ ...popContactForm, [prop]: event.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    //console.log(popContactForm);

    if (
      popContactForm !== undefined &&
      popContactForm.name !== "" &&
      popContactForm.phone !== "" &&
      popContactForm.email !== ""
    ) {
      var res = await db
        .collection("Appliedpop")
        // .doc(gymRef)
        // .collection('members')
        .add({
          popContactForm,
          createdAt: new Date(),
          status: true,
          inactiveSince: null,
          //feepaid: memberInfo.membershipinfo.enddate < new Date() ? false : true,
          //paymentHistory,
        })
        .then(() => setFeedback(true));

      emailjs
        .sendForm(
          "service_2pudlnv",
          "template_7080g1f",
          form.current,
          "k-5M5JsrHAcS_m5u7"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
      //console.log("Record saved");
    } else {
      alert("Please enter valid details");
    }
    setPopContactForm({ name: "", phone: "", email: "" });
    //sending the data to parent component

    //props.getData(nextStep);
    //dispatch({ type: 'ADD_MEMBERSHIP_DATA', payload: membershipdata });
    //Cookies.set(`memberdata1`, JSON.stringify(membershipdata));
  };

  return (
    <>
      <div className="objectives">
        <div className="objectives__title">Professor of Practice (PoP)</div>

        <div className="objectives__content">
          <div className="objectives__content__text">
            At COSMOS, we believe in the power of expertise and real-world
            experience. That's why we have established the PoP program, bringing
            together distinguished individuals who are subject matter experts in
            their respective fields. PoPs encompass professors, professionals
            from various industries, and renowned researchers who contribute
            their wealth of knowledge and practical insights to address
            sustainability challenges.
          </div>
          <div className="objectives__content__icons"></div>
        </div>
      </div>
      <div className="SGC__colorliner"></div>

      <Multiplepoints
        text1="Why"
        text2="Professor of Practice?"
        data={whypop}
      />

      <div className="SGC__colorliner"></div>
      <div className="pop__poparea">
        <div className="pop__poparea__popsection">
          <div className="pop__poparea__popsection__section1">
            <div className="pop__poparea__popsection__section1__title">
              Professor of Practice for Arcticturn Foundation
            </div>
            <div className="pop__poparea__popsection__section1__text">
              We believe that the PoP program at COSMOS is a catalyst for
              driving change and creating a sustainable future.
            </div>
          </div>
          <div className="pop__poparea__popsection__section2">
            {poplist.map((pop) =>
              pop.status === "open" ? (
                <Link className="navbar__Mainheader__link" to={pop.link}>
                  <Popcard
                    img={pop.img}
                    title={pop.title}
                    subtext={pop.industry}
                    key={pop.value}
                  />
                </Link>
              ) : (
                <Popcard
                  img={pop.img}
                  title={pop.title}
                  subtext={pop.industry}
                  key={pop.value}
                />
              )
            )}
          </div>
        </div>
      </div>
      <div className="SGC__colorliner"></div>

      <div className="popsection__joinpop">
        <div className="popsection__joinpop__leftsection">
          <div className="aboutpage__whatwedosection_title">
            Are you <br />
            <div className="aboutpage__whatwedosection_title_subtitle">
              A Subject Matter Expert ?{" "}
            </div>
          </div>

          <div className="popsection__joinpop__leftsection__subtitle">
            We invite you to become a part of our PoP network.
          </div>
          <div className="popsection__joinpop__leftsection__para">
            By joining COSMOS as a PoP, you will have the opportunity to
            collaborate with other renowned experts, engage in impactful
            projects, contribute to cutting-edge research, and influence the
            next generation of sustainability leaders.
          </div>
        </div>

        <div className="popsection__joinpop__rightsection">
          <div className="contactsection__contactform">
            <div className="contactsection__contactform-form">
              <form ref={form} onSubmit={onSubmit}>
                <div className="contactsection__contactform-form-allinput">
                  <div className="contactsection__contactform-form-allinput-input">
                    Name:
                    <input
                      required
                      onChange={handleChange("name")}
                      value={popContactForm.name}
                      type="text"
                      name="name"
                      className="contactsection__contactform-form-allinput-input-inputfield"
                    />
                  </div>

                  <div className="contactsection__contactform-form-allinput-input">
                    Phone Number:
                    <input
                      required
                      onChange={handleChange("phone")}
                      value={popContactForm.phone}
                      type="number"
                      name="phone"
                      className="contactsection__contactform-form-allinput-input-inputfield"
                    />
                  </div>
                  <div className="contactsection__contactform-form-allinput-input">
                    Email:
                    <input
                      required
                      onChange={handleChange("email")}
                      value={popContactForm.email}
                      type="email"
                      name="email"
                      className="contactsection__contactform-form-allinput-input-inputfield"
                    />
                  </div>
                </div>
              </form>
              <button
                onClick={onSubmit}
                className="contactsection__contactform-form-button"
              >
                {feedback ? "Thank you" : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Pop;
