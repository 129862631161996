import React from "react";

const Listwithheaders = (props) => {
  return (
    <div className="bloglist2">
      <ul>
        {props.text.list.map((item) => (
          <div>
            <li className="" key={item.title}>
              <div className="bloglist2listtitle">{item.title}</div>
              <div className="bloglist2listtext">{item.text}</div>
            </li>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default Listwithheaders;
