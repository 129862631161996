import React from "react";

const Blogparagraph = (props) => {
  return (
    <div className="blogparagraph" style={{ textAlign: props.align }}>
      {props.text}
    </div>
  );
};

export default Blogparagraph;
