import React, { useEffect, useState } from "react";
import { poplist } from "../../utils/poplist";
import { db } from "../../../Firebase/config";
import { firebaseLooper, formatFirebaseTimestamp } from "../../utils/functions";

const Appliedpop = (props) => {
  const [poplist, setPoplist] = useState(null);
  useEffect(() => {
    db.collection("Appliedpop")
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          var res = firebaseLooper(snapshot);
          console.log(res);
          setPoplist(res);
        }
      });
  }, []);

  return (
    <div>
      <div className="editpop__header">Applied Users for PoP</div>{" "}
      <div>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Name</th>
              <th>Contact Number</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {poplist &&
              poplist.map((pop) => (
                <tr>
                  <td>{formatFirebaseTimestamp(pop.createdAt)}</td>
                  <td>{pop.popContactForm.name}</td>
                  <td>{pop.popContactForm.phone}</td>
                  <td>{pop.popContactForm.email}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Appliedpop;
