import React from "react";
import Anand from "../Images/ANAND KHANDEKAR.png";
import Pranav from "../Images/PRANAV CHAWARE.png";
import Saurabh from "../Images/Saurabh.jpg";
import Raj from "../Images/Raj_Profile.jpeg";
import Linkedinlogo from "../Images/linked in .png";

const Founders = () => {
  return (
    <div id="founders">
      <div className="foundersHeader">Meet Our Team</div>
      {/* <div className="foundersHeader__subHeader">One Team, One Dream</div> */}
      <div className="founders">
        <div className="founders__card">
          <img
            src={Anand}
            alt="Anand Khandekar"
            className="founders__card__img"
          />
          <div className="founders__card__section">
            <div className="founders__card__name">Anand Khandekar</div>
            <a
              href="https://www.linkedin.com/in/therunningprofessor/"
              target="_blank" rel="noreferrer"
            >
              <img
                src={Linkedinlogo}
                className="founders__card__linkedin"
              ></img>
            </a>
          </div>
        </div>
        <div className="founders__card">
          <img
            src={Pranav}
            alt="Pranav chaware"
            className="founders__card__img"
          />

          <div className="founders__card__section">
            <div className="founders__card__name">Pranav Chaware</div>
            <a
              href="https://www.linkedin.com/in/pranavchaware/"
              target="_blank" rel="noreferrer"
            >
              <img
                src={Linkedinlogo}
                className="founders__card__linkedin"
              ></img>
            </a>
          </div>
        </div>
        <div className="founders__card">
          <img src={Raj} alt="Raj kumbhar" className="founders__card__img" />

          <div className="founders__card__section">
            <div className="founders__card__name">Raj Kumbhar</div>
            <a href="https://www.linkedin.com/in/rajkumbhar/" target="_blank" rel="noreferrer">
              <img
                src={Linkedinlogo}
                className="founders__card__linkedin"
              ></img>
            </a>
          </div>
        </div>
        <div className="founders__card">
          <img
            src={Saurabh}
            alt="Saurabh Shighwan"
            className="founders__card__img"
          />
          <div className="founders__card__section">
            <div className="founders__card__name">Saurabh Shighwan</div>
            <a
              href="https://www.linkedin.com/in/saurabh-r-shigwan-2b4053150/"
              target="_blank" rel="noreferrer"
            >
              <img
                src={Linkedinlogo}
                className="founders__card__linkedin"
              ></img>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Founders;
