import React, { useEffect, useState } from "react";
import Blogparagraph from "../component/BlogsComponents/Blogparagraph";
import Title from "../component/BlogsComponents/Title";
import { objectives } from "../utils/Cosmoshub";
import { AiFillCaretDown, AiFillCaretRight } from "react-icons/ai";
import ObjectiveContentSection from "../component/ObjectivesContentSection";
import Footer from "./Footer";
import SGC from "../../Images/SGC.png";
import Detailedcards from "../component/DetailedCards";

const Objectives = (props) => {
  const [arrow, setArrow] = useState(true);
  const [clickedvalue, setClickedvalue] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const clickedObjective = (con) => {
    // if (clickedvalue != null && !arrow) {
    //   alert(clickedvalue);
    // }

    setClickedvalue(con);
    setArrow(!arrow);
  };
  return (
    <>
      <div className="objectives">
        <div>
          {/* <div className="objectives__title__subtitle">Objectives of</div> */}
          <div className="objectives__title">Objectives</div>
        </div>

        <div className="objectives__content">
          <div className="objectives__content__text">
            The objectives of the ArcticTurn Foundation (ATF) are centered
            around promoting interdisciplinary collaboration, establishing
            global partnerships, facilitating global mobility, driving
            sustainable development initiatives, and engaging in outreach and
            education for sustainability. ATF aims to leverage these objectives
            to contribute to the achievement of the Sustainable Development
            Goals (SDGs) and create a more sustainable and inclusive future for
            all.
          </div>
          <div className="objectives__content__icons"></div>
        </div>
      </div>
      <div className="SGC__colorliner"></div>

      <div className="SGC">
        <div className="SGC_1">
          <div className="SGC__title">Sustainable Development Goals</div>
          {/* <Blogparagraph
            text="The Sustainable Development Goals (SDGs) are a global framework for
            all to use to achieve a more sustainable future. They recognise that
            ending poverty must go hand-in-hand with strategies that build
            economic growth and address a range of social needs including
            education, health, social protection, and job opportunities, while
            tackling climate change and environmental protection, peace and
            justice."
            align="left"
          /> */}
          <div
            className="aboutpage__pov__paragraph"
            style={{ color: "rgb(1, 51, 51)" }}
          >
            The Sustainable Development Goals (SDGs) are a global framework for
            all to use to achieve a more sustainable future. They recognise that
            ending poverty must go hand-in-hand with strategies that build
            economic growth and address a range of social needs including
            education, health, social protection, and job opportunities, while
            tackling climate change and environmental protection, peace and
            justice.
          </div>
        </div>
        <div className="SGC_2">
          <img className="SGC_2_img" src={SGC} />
        </div>
      </div>

      <div className="objectives__goals">
        {objectives.map((obj) => (
          <Detailedcards data={obj} details={true} />
        ))}
      </div>

      {/* {objectives.map((obj) => (
        <div className="cosmoshub__section" key={obj.value}>
          <div
            className="cosmoshub__section__card"
            onClick={(con) => clickedObjective(obj.value)}
          >
            <div className="cosmoshub__section__card_arrow">
              {clickedvalue === obj.value && !arrow ? (
                <AiFillCaretDown />
              ) : (
                <AiFillCaretRight />
              )}
            </div>
            <div className="cosmoshub__section__card_title">{obj.title}</div>
          </div>

          {clickedvalue === obj.value && !arrow ? (
            <ObjectiveContentSection value={obj} icon={true} />
          ) : (
            ""
          )}
        </div>
      ))} */}

      <Footer />
    </>
  );
};

export default Objectives;
