import React from "react";

const Header3 = (props) => {
  return (
    <div className="header3text" style={{ textAlign: props.align }}>
      {props.header != null ? (
        <div className="header3text__header">{props.header} </div>
      ) : null}{" "}
      {props.text}
    </div>
  );
};

export default Header3;
