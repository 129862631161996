import React from "react";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";
import { AiOutlineCopyrightCircle } from "react-icons/ai";
//import { Link } from "react-scroll";
import Logo from "../../Images/Logo.png";
import { Link } from "react-router-dom";

const Footer = (props) => {
  const changePageFunction = (number) => {
    props.setPage(number);
  };

  return (
    <div className="footer">
      <div className="footer__links">
        {/* <div className="footer__links-link">
          <div
            className="footer__links-link__logo"
            onClick={() => changePageFunction(0)}
          >
            <img src={Logo} className="footer__logo" />
          </div>
        </div> */}

        <div className="footer__links-link">
          <div className="footer__links-link__heading">
            ArcticTurn Foundation
          </div>
          <div className="footer__links-link__items-nolink">
            Copyright @2024
          </div>
        </div>

        <div className="footer__links-link">
          <div className="footer__links-link__heading">Contact Us</div>

          <div className="footer__links-link__items-nolink">
            Kothrud, Pune, 411038
          </div>

          {/* <div className="footer__links-link__items">Tel: 9004916456</div> */}

          <a
            className="footer__links-link__items-link"
            href="mailto:info@arcticturn.in"
          >
            Email: info@arcticturn.in
          </a>
        </div>

        <div className="footer__links-link">
          <div className="footer__links-link__heading">Support</div>
          <Link className="footer__links-link__items-link" to="/termsofuse">
            Terms of Use
          </Link>
          {/* <div className="footer__links-link__items-link">Privacy Policy</div> */}
          <Link className="footer__links-link__items-link" to="/privacypolicy">
            Privacy Policy
          </Link>
          {/* <div className="footer__links-link__items">FAQ</div> */}
          {/* <div className="footer__links-link__items">Contact Us</div> */}
          {/* <div className="footer__links-link__items">Terms & Conditions</div> */}
          {/* <div className="footer__links-link__items">Privacy Policy</div> */}
        </div>
      </div>

      {/* social media logo */}
      {/* <div className="footer__icons">
        <div className="footer__icons-icon">
          <FaFacebook />
        </div>
        <div className="footer__icons-icon">
          <FaTwitter />
        </div>
        <div className="footer__icons-icon">
          <FaInstagram />
        </div>
        <div className="footer__icons-icon">
          <FaLinkedin />
        </div>
        <div className="footer__icons-icon">
          <FaYoutube />
        </div>
      </div> */}
    </div>
  );
};

export default Footer;
