import React, { useEffect, useState } from "react";
import Footer from "./Footer";

const Subscribe = () => {
  const [formData, setFormData] = useState({ email: "", name: "" });
  const [feedback, setFeedback] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (prop) => (event) => {
    setFormData({ ...formData, [prop]: event.target.value });
    setErrors({ ...errors, [prop]: "" });
  };

  const validateForm = () => {
    let isValid = true;
    let newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
      isValid = false;
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async () => {
    setErrors({});
    setFeedback("");
    if (validateForm()) {
      setIsLoading(true);
      try {
        const response = await fetch(
          "https://us-central1-arcticturn1.cloudfunctions.net/addToMailingList",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...formData,
              email: formData.email.toLowerCase(), // Convert email to lowercase here
            }),
          }
        );

        const data = await response.json();

        if (response.ok) {
          setFeedback(data.message || "Thank you for subscribing!");
          setFormData({ email: "", name: "" });
        } else {
          throw new Error(data.error || "An error occurred");
        }
      } catch (error) {
        console.error("Error adding subscriber: ", error);
        setFeedback(error.message || "An error occurred. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="blogs">
      <div className="blogs__header">Subscribe to ATF Newsletter </div>
      <div className="subscribe_container">
        <div className="contactsection__contactform-form">
          <div className="contactsection__contactform-form-allinput">
            <div className="contactsection__contactform-form-allinput-input">
              Your Name:
              <input
                required
                onChange={handleChange("name")}
                value={formData.name}
                type="text"
                name="name"
                className="contactsection__contactform-form-allinput-input-inputfield"
              />
              {errors.name && (
                <div className="error-message">{errors.name}</div>
              )}
            </div>
            <div className="contactsection__contactform-form-allinput-input">
              Your Email Id:
              <input
                required
                onChange={handleChange("email")}
                value={formData.email}
                type="email"
                name="email"
                className="contactsection__contactform-form-allinput-input-inputfield"
              />
              {errors.email && (
                <div className="error-message">{errors.email}</div>
              )}
            </div>
            <div>
              <button
                className="contactsection__contactform-form-button"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? "Subscribing..." : "Subscribe"}
              </button>
            </div>
            {feedback && (
              <div
                className="warning-message__success"
                style={{ marginTop: "3rem" }}
              >
                {feedback}
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Subscribe;
