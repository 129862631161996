import { useEffect } from "react";
import React from "react";
import Onesectionmultiplepara from "../../component/BlogsComponents/Onesectionmultiplepara";
import { termsofuselist } from "../../utils/AboutInfo";
import Footer from "../Footer";

const Privacypolicy = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="privacypolicy">
      <div className="objectives">
        <div className="objectives__title">Terms of Use</div>
        <div className="objectives__content">
          <div className="objectives__content__text">
            Welcome to ArcticTurn Foundation ("us," "we," or "our"). By
            accessing or using www.arcticturn.in (the "Site"), you acknowledge
            and agree to be bound by these Terms of Use. If you do not agree
            with any part of these terms, please refrain from using the Site.
          </div>
        </div>
      </div>
      <div className="SGC__colorliner"></div>
      <div className="privacypolicy__section">
        {termsofuselist.map((policy) => (
          <Onesectionmultiplepara
            title={policy.title}
            para1={policy.para1 ? policy.para1 : null}
            para2={policy.para2 ? policy.para2 : null}
            para3={policy.para3 ? policy.para3 : null}
            para4={policy.para4 ? policy.para4 : null}
            para5={policy.para5 ? policy.para5 : null}
            para6={policy.para6 ? policy.para6 : null}
            para7={policy.para7 ? policy.para7 : null}
          />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Privacypolicy;
