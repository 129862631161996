export const firebaseLooper = (snapshot) => {
  let data = [];
  snapshot.forEach((doc) => {
    data.push({
      ...doc.data(),
      id: doc.id,
    });
  });

  return data;
};

export const generateUniqueId = () => {
  const timestamp = new Date().getTime().toString(36); // Convert current timestamp to base36
  const randomString = Math.random().toString(36).substr(2, 5); // Generate random string
  const uniqueId = timestamp + randomString; // Concatenate timestamp and random string
  return uniqueId;
};

export const replaceSpacesWithDash = (inputString) => {
  // Remove spaces and replace them with "-"
  const modifiedString = inputString.replace(/\s+/g, "-");
  return modifiedString;
};

export const formatFirebaseTimestamp = (timestamp) => {
  // Convert Firebase Timestamp to JavaScript Date object
  var date = timestamp.toDate();

  // Format the date as "2 Oct 2023"
  var formattedDate = new Intl.DateTimeFormat("en", {
    day: "numeric",
    month: "short",
    year: "numeric",
  }).format(date);

  return formattedDate;
};

export const getTopRecentDates = (datesArray, topN) => {
  // Sort the dates array in descending order (most recent first)

  datesArray.sort((a, b) => new Date(b.date) - new Date(a.date));

  // Return the top N recent dates
  return datesArray.slice(0, topN);
  //console.log("datearray ", datesArray);
  //return null;
};
