import { useEffect } from "react";
import React from "react";
import Onesectionmultiplepara from "../../component/BlogsComponents/Onesectionmultiplepara";
import { privacypolicylist } from "../../utils/AboutInfo";
import Footer from "../Footer";

const Privacypolicy = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="privacypolicy">
      <div className="objectives">
        <div className="objectives__title">Privacy Policy</div>
        <div className="objectives__content">
          <div className="objectives__content__text">
            ArcticTurn Foundation ("us," "we," or "our") operates
            www.arcticturn.in (the "Site"). This page informs you of our
            policies regarding the collection, use, and disclosure of Personal
            Information we receive from users of the Site.
          </div>
        </div>
      </div>
      <div className="SGC__colorliner"></div>
      <div className="privacypolicy__section">
        {privacypolicylist.map((policy) => (
          <Onesectionmultiplepara
            key={policy.value}
            title={policy.title}
            para1={policy.para1 ? policy.para1 : null}
            para2={policy.para2 ? policy.para2 : null}
            para3={policy.para3 ? policy.para3 : null}
            para4={policy.para4 ? policy.para4 : null}
            para5={policy.para5 ? policy.para5 : null}
            para6={policy.para6 ? policy.para6 : null}
            para7={policy.para7 ? policy.para7 : null}
          />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Privacypolicy;
