import React, { useEffect } from "react";
import { blogs } from "../utils/BlogsList";
import Title from "../component/BlogsComponents/Title";
import Header3 from "../component/BlogsComponents/Header3";
import Blogimage from "../component/BlogsComponents/Blogimage";
import Listwithoutheaders from "../component/BlogsComponents/Listwithoutheaders";
import Header2 from "../component/BlogsComponents/Header2";
import Blogparagraph from "../component/BlogsComponents/Blogparagraph";
import Listwithheaders from "../component/BlogsComponents/Listwithheaders";
import Footer from "../homepage/Footer";
import { researchprojects } from "../utils/ProjectList";
import { poplist } from "../utils/poplist";
import Popcard from "../component/Popcard";
import { Link } from "react-router-dom";

const Projectpage = (props) => {
  let header = null;
  let flag = true;
  let membersofthisproj = [];

  researchprojects.forEach((proj) => {
    if (props.link === proj.title && flag) {
      header = proj;
      flag = false;
    }
  });

  const fullblog = header;

  fullblog.PIteam.forEach((teammate) =>
    poplist.forEach((pop) => {
      if (pop.id === teammate.id) {
        membersofthisproj.push(pop);
      }
    })
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="blogpage">
        <Title text={fullblog.title} align="left" />
        <Header3 text={fullblog.domain} align="left" />
        <Header3 text={fullblog.date} align="left" />
        <Blogimage text={fullblog.img} align="left" />

        {fullblog.status === "open" && fullblog.introduction1 != null ? (
          <>
            <Header2 text="Introduction" />
            <Blogparagraph text={fullblog.introduction1} />
            {fullblog.introduction2 !== null ? (
              <Blogparagraph text={fullblog.introduction2} />
            ) : null}
            {fullblog.introduction3 !== null ? (
              <Blogparagraph text={fullblog.introduction3} />
            ) : null}

            {/* <Header2 text="Objectives" />
            <Header3 text={fullblog.list1.subtext} align="left" />
            <Listwithoutheaders text={fullblog.list1} /> */}

            {/* <Header2 text={fullblog.features.title} /> */}
            <Header3 text={fullblog.features.subtext} align="left" />
            <Listwithheaders text={fullblog.features} />

            {fullblog.list3 != null ? (
              <div>
                <Header2 text={fullblog.list3.title} />
                <Header3 text={fullblog.list3.subtext} align="left" />
                <Listwithheaders text={fullblog.list3} />
                <Blogparagraph text={fullblog.list3.paragraph} />
              </div>
            ) : null}

            <Header2 text="PI Team" />
            {/* {fullblog.PIteam.forEach((teammate) =>
              poplist.map((pop) => {
                if (pop.id === teammate.id) {
                  <Popcard
                    img={pop.img}
                    title={pop.title}
                    subtext={pop.industry}
                    key={pop.value}
                  />;
                }
              })
            )} */}

            <div className="blogsection__blogs">
              {membersofthisproj.map((pop) =>
                pop.status === "open" ? (
                  <Link
                    className="navbar__Mainheader__link"
                    to={`../pop/${pop.link}`}
                  >
                    <Popcard
                      img={pop.img}
                      title={pop.title}
                      subtext={pop.industry}
                      key={pop.value}
                    />
                  </Link>
                ) : (
                  <Popcard
                    img={pop.img}
                    title={pop.title}
                    subtext={pop.industry}
                    key={pop.value}
                  />
                )
              )}
            </div>
            <Header2 text="Co-PI Team" />
            {fullblog.COPIteam.map((teammate) => (
              <>
                <div style={{ fontSize: "2rem" }}>{teammate.name}</div>
              </>
            ))}
          </>
        ) : (
          <div
            style={{ marginTop: "5rem", textAlign: "center", fontSize: "2rem" }}
          >
            [To be Updated]
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Projectpage;
