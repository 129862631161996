import React from "react";
import SGC17 from "../../Images/SGC17.jpeg";
import SGC12 from "../../Images/SGC12.jpeg";
import SGC9 from "../../Images/SGC9.jpeg";
import SGC4 from "../../Images/SGC4.jpeg";

const Detailedcards = (props) => {
  console.log(props.data);
  return (
    <div className="detailedcards">
      <div
        className="detailedcards__title"
        style={{ textAlign: props.textalign }}
      >
        {props?.data?.title}
      </div>
      {props?.data?.rule?.map((rule) => (
        <>
          <div className="detailedcards__goal" key={rule.value}>
            {rule.rule === 4 && (
              <img src={SGC4} className="detailedcards__goal__img" />
            )}
            {rule.rule === 17 && (
              <img src={SGC17} className="detailedcards__goal__img" />
            )}
            {rule.rule === 12 && (
              <img src={SGC12} className="detailedcards__goal__img" />
            )}
            {rule.rule === 9 && (
              <img src={SGC9} className="detailedcards__goal__img" />
            )}
            <div className="detailedcards__goal__textsection">
              {rule.title}
              <div className="detailedcards__goal__label">
                Follows {rule.rule}th Goal of SDG
              </div>
            </div>
          </div>
        </>
      ))}

      {props.details ? (
        <div className="detailedcards__text">{props.data.text}</div>
      ) : null}
    </div>
  );
};

export default Detailedcards;
