import React, { useEffect, useState } from "react";
import { db } from "../../../Firebase/config";
import {
  firebaseLooper,
  generateUniqueId,
  replaceSpacesWithDash,
} from "../../utils/functions";

const Addlinkedinpost = (props) => {
  const [formopen, setFormopen] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [postlist, setPostlist] = useState(null);
  const [mode, setMode] = useState(null);
  const [postid, setPostid] = useState(null);
  const [postdetails, setPostdetails] = useState({
    headline: null,
    date: null,
    content: null,
    img: null,
    postlink: null,
  });

  const handleChange = (prop) => (event) => {
    setPostdetails({ ...postdetails, [prop]: event.target.value });
  };

  useEffect(() => {
    db.collection("postlist")
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          var res = firebaseLooper(snapshot);
          //console.log(res);
          setPostlist(res);
        }
      });
  }, [formopen]);

  const deletePop = async (e) => {
    var res = await db
      .collection("postlist")
      .where("uid", "==", postid)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          // Update the document
          db.collection("postlist")
            .doc(doc.id)
            .delete()
            .then(() => {
              setFeedback(true);
              console.log("Document deleted updated!");
            });
        });
      });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log(postdetails);

    if (
      postdetails.headline !== null &&
      postdetails.date !== null &&
      postdetails.content !== null &&
      postdetails.img !== null &&
      postdetails.postlink !== null
    ) {
      if (mode === "edit") {
        var res = await db
          .collection("postlist")
          .where("uid", "==", postid)
          .get()
          .then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              // Update the document
              db.collection("postlist")
                .doc(doc.id)
                .update({
                  headline: postdetails.headline,
                  date: postdetails.date,
                  content: postdetails.content,
                  img: postdetails.img,
                  postlink: postdetails.postlink,
                })
                .then(function () {
                  console.log("Document successfully updated!");
                  setFeedback(true);
                  setFormopen(false);
                  setPostdetails({
                    headline: null,
                    date: null,
                    content: null,
                    img: null,
                    postlink: null,
                  });
                })
                .catch(function (error) {
                  console.error("Error updating document: ", error);
                });
            });
          });
      } else {
        var res = await db
          .collection("postlist")
          .add({
            headline: postdetails.headline,
            date: postdetails.date,
            content: postdetails.content,
            img: postdetails.img,
            uid: generateUniqueId(),
            postlink: postdetails.postlink,
          })
          .then(() => {
            setFeedback(true);
            setFormopen(false);
            setPostdetails({
              headline: null,
              date: null,
              content: null,
              img: null,
              postlink: null,
            });
          });
      }
      console.log("Record saved");
    } else {
      alert("Please enter valid details");
    }
  };

  return (
    <div className="editpop">
      {!formopen ? (
        <>
          <div className="editpop__primaryarea">
            <div className="editpop__header">Linkedin Post List</div>{" "}
            <div
              className="editpop__addbutton"
              onClick={() => {
                setFormopen(true);
              }}
            >
              {" "}
              Add New Linkedin Post
            </div>
          </div>
          <div>
            <table>
              <thead>
                <tr>
                  <th>uid</th>
                  <th>Headline</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {postlist &&
                  postlist.map((post) => (
                    <tr>
                      <td>{post.uid}</td>
                      <td>{post.headline}</td>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setMode("edit");
                          setPostid(post.uid);
                          setFormopen(true);
                          setPostdetails({
                            headline: post.headline,
                            date: post.date,
                            content: post.content,
                            img: post.img,
                            postlink: post.postlink,
                          });
                        }}
                      >
                        Edit
                      </td>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() => deletePop()}
                      >
                        Delete
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div className="editpop__formarea">
          <div className="contactsection__contactform-form">
            <div className="editpopcontactformheaders">
              <div className="editpopcontactformheaders__tags"></div>
              <div
                className="closebuttononfrom"
                onClick={() => {
                  setFormopen(false);
                  setMode(null);
                  setPostdetails({
                    headline: null,
                    date: null,
                    content: null,
                    img: null,
                    postlink: null,
                  });
                }}
              >
                X
              </div>
            </div>
            <form onSubmit={onSubmit}>
              <div className="contactsection__contactform-form-allinput">
                <div className="contactsection__contactform-form-allinput-input">
                  Headline:
                  <input
                    required
                    onChange={handleChange("headline")}
                    value={postdetails.headline}
                    type="text"
                    name="headline"
                    className="contactsection__contactform-form-allinput-input-inputfield"
                  />
                </div>
                <div className="contactsection__contactform-form-allinput-input">
                  Date:
                  <input
                    required
                    onChange={handleChange("date")}
                    value={postdetails.date}
                    type="date"
                    name="date"
                    className="contactsection__contactform-form-allinput-input-inputfield"
                  />
                </div>
                <div className="contactsection__contactform-form-allinput-input">
                  Content:
                  <input
                    required
                    onChange={handleChange("content")}
                    value={postdetails.content}
                    type="text"
                    name="content"
                    className="contactsection__contactform-form-allinput-input-inputfield"
                  />
                </div>
                <div className="contactsection__contactform-form-allinput-input">
                  Image Link:
                  <input
                    required
                    onChange={handleChange("img")}
                    value={postdetails.img}
                    type="text"
                    name="img"
                    className="contactsection__contactform-form-allinput-input-inputfield"
                  />
                </div>
                <div className="contactsection__contactform-form-allinput-input">
                  Post Link:
                  <input
                    required
                    onChange={handleChange("postlink")}
                    value={postdetails.postlink}
                    type="text"
                    name="postlink"
                    className="contactsection__contactform-form-allinput-input-inputfield"
                  />
                </div>
              </div>
            </form>

            <button
              onClick={onSubmit}
              className="contactsection__contactform-form-button"
            >
              {mode === "edit" ? "Update" : "Submit"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Addlinkedinpost;
