export const researchprojects = [
  {
    id: "ATFPHEDM001",
    status: "open",
    value: 1,
    date: "",
    title: "Early Detection of Malnutrition at India’s Nutritional Hubs",
    domain: "Public Health",
    collaboration: "BKL Walawalkar’s Hospital,Diagnostic and Research Center.",
    subtext:
      "India's malnutrition problem, especially that of young children, is one of the most pressing public health issues. It causes about half of child deaths and is a major cause of morbidity in children.",
    //img: "https://imgnew.outlookindia.com/public/uploads/articles/2021/5/1/Malnutrition.jpg",
    img: "https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/malnutritioninfo.png?alt=media&token=23ee70da-546e-4195-afe5-aacf192c929a",
    //PIteam: ["Dr. Amit Salvi", "Dr. Mandar Karyakarte"],
    PIteam: [
      { name: "Dr. Amit Salvi", id: "ATFAS0003" },
      { name: "Dr. Mandar Karyakarte", id: "ATFMK0004" },
    ],
    COPIteam: [
      { name: "Dr. Charudatta Jogelkar", id: null },
      { name: "Mr. Anand Khandekar", id: null },
      { name: "Mr. Pranav Chaware", id: null },
    ],
    introduction1:
      "India's malnutrition problem, especially that of young children, is one of the most pressing public health issues. It causes about half of child deaths and is a major cause of morbidity in children. It has medical and social disorders rooted in poverty and discrimination. It has an economic ripple effect that hampers development.",
    introduction2:
      "As reported elsewhere, high prevalence of under-nutrition (BMI<18.5) was observed in adolescent girls of rural areas of Ratnagiri District on the basis of this index. This indicates that adolescent girls are the worst sufferers of the ravages of various forms of malnutrition since the beginning of adolescence. This phenomenon remains uninterrupted throughout their life. Variations in the extent of under-nutrition among adolescent girls could be attributed to differences in socio-cultural practices, level of socio-economic development, value attached to a girl child and prevailing dietary practices in different settings",
    introduction3:
      "At BKL Walawalkar Hospital,Diagnostic and Research Center, Dervan a team of investigators  found adolescent girls to be anaemic on Iron and folic acid tablets. Health education about Nutrition and menstrual problems was provided to them. After the completion of stipulated time period their CBC & Hb estimation is planned to find out the prevalence & improvement in the Hb level of adolescent girls.",
    features: {
      title: "",
      subtext: "Features of this project include:",
      list: [
        {
          title: "Nutritional Awareness",
          text: "Promoting nutritional awareness at the ground level is by educating the public on the importance and nutritional quality of locally available low-cost foods. The best recipes for preparing proper weaning foods and supplementary foods from low-cost, locally available ingredients are shared with women of the family via self-help groups. Adolescent health initiative (AHI) is being implemented in collaboration with the Community Health Department and Gynaecology Department",
        },
        {
          title: "Early Detection of Malnutrition",
          text: "A well recorded growth health chart of newborn babies and pregnant womens can detect malnutrition very early. The mid-day meal scheme must also be subjected to a social audit in every district by the states and union territories.Use of information technology to improve program monitoring is also being considered.",
        },
        {
          title: "",
          text: "This is the first adolescent cohort from Konkan region where no prior detailed epidemiological data is available apart from few government surveys.",
        },
        {
          title: "",
          text: "This study will enable us to understand the health profile of the region’s adolescent girls and assess their health priorities. Long-term follow-up will provide longitudinal assessment of the risk factors for the development of non-communicable diseases (NCDs) in their adulthood and in their future offsprings.",
        },
        {
          title: "Services Planned and Provided",
          text: "General examination, Nutrition Advice, Detection & treatment of anaemia, Menstrual problems, Sex education, Reproductive problems",
        },
      ],
      paragraph: null,
    },
  },
  {
    id: "ATFWMAPZLD001",
    status: "close",
    value: 2,
    date: "",
    title:
      "Assessment of Performance of Zero Liquid Discharge operations in tanneries",
    domain: "Wastewater management",
    collaboration: "SROTT India Pvt Ltd and Tai Yu Leather Co. Ltd., Vietnam.",
    subtext:
      "Leather industry is a very old manufacturing sector producing a broad range of goods such as leather footwear, leather bags, leather garments, and so on.",
    img: "https://genesiswatertech.com/wp-content/uploads/2016/08/municipal-wastewater.jpg",
  },
  {
    id: "ATFSIODDT001",
    status: "close",
    value: 3,
    date: "",
    title: "Ontologies to develop Digital Twins for Smart Manufacturing.",
    domain: "Sustainable Infrastructure",
    collaboration: "VIIT, Pune and TCS Research.",
    subtext:
      "The Indian auto industry is one of the fastest-growing and most promising sectors in the country, contributing significantly to the economy and employment. ",
    img: "https://www.fastradius.com/wp-content/uploads/2020/07/Digital-Twin-Hero-scaled.jpg",
  },
  {
    id: "ATFPHIIBBDMO001",
    status: "close",
    value: 4,
    date: "",
    title:
      "Informatics for Integrating Biology and Bedside for developing Medical Ontologies",
    domain: "Public Health",
    collaboration:
      "TCS Research and Department of BioMedical Informatics, Harvard Medical School.",
    subtext:
      "Informatics for Integrating Biology and the Bedside (i2b2) is an open-source software platform that is used for clinical research and translational medicine.",
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzyW0osKIYRKxYgTUHeVz9-NpT7ljPC-TH2A&s",
  },
  {
    id: "ATFPHPEOGCHPLS001",
    status: "open",
    value: 5,
    date: "",
    title:
      "Process equipment and Ontologies for GMP complaint Human Platelet Lysate Separation",
    domain: "Public Health",
    collaboration: "BKL Walawalkar’s Hospital,Diagnostic and Research Center.",
    subtext:
      "The process equipment and ontologies for GMP compliant human platelet lysate separation are the tools and resources that are used to produce human platelet lysate (HPL).",
    img: "https://miro.medium.com/v2/resize:fit:1179/1*h8-On61QGtJFYThAs9OO6Q.png",
    PIteam: [{ name: "Mr. Parag Apte", id: "ATFPA0005" }],
    COPIteam: [
      { name: "Dr. Charudatta Jogelkar", id: null },
      { name: "Mr. Anand Khandekar", id: null },
      { name: "Mr. Pranav Chaware", id: null },
    ],
  },
  {
    id: "ATFSICELL001",
    status: "open",
    value: 6,
    date: "",
    title: "Centre of Excellence - Leather Lab",
    domain: "Sustainable Infrastructure",
    collaboration: "MIT-ID, Pune.",
    // "MIT-ID, Pune,Northampton University, UK and Tata International Leather division.",
    subtext:
      "The studio will provide a space for students and faculty to experiment with leather as a material, and to develop new products and applications.",
    img: "https://indbiz.gov.in/wp-content/uploads/2021/06/MicrosoftTeams-image-58.jpg",
    PIteam: [
      { name: "Sateesh Damle", id: "ATFSD0001" },
      { name: "Prof. Dr. Nachiket Thakur", id: "ATFNT0002" },
    ],
    COPIteam: [
      { name: "Prof. Amit Sinha" },
      { name: "Mr. Anand Khandekar" },
      { name: "Mr. Pranav Chaware" },
    ],
    introduction1:
      "The Leather Studio, a research and development initiative that we propose to establish within the premises of MIT Institute of Design (MIT-ID) in Pune. The Leather Studio will be a center of excellence for sustainable leather product design, development, and research, aimed at revolutionizing the leather industry's practices.",
    features: {
      title: "",
      subtext: "Key components of the Leather Studio collaboration proposal:",
      list: [
        {
          title: "State-of-the-Art Facility:",
          text: "The Leather Studio will be equipped with cutting-edge design and manufacturing equipment, enabling the creation of innovative and sustainable leather products.",
        },
        {
          title: "Research and Innovation",
          text: "Our collaboration will focus on conducting research on eco-friendly leather processing methods, exploring alternative materials, and developing resource-efficient production techniques.",
        },
        {
          title: "Talent Development",
          text: "The Leather Studio will provide a platform for aspiring designers and researchers to work on real-world sustainability projects and nurture the next generation of leaders in sustainable design",
        },
        {
          title: "Market-Ready Solutions",
          text: "We aim to develop commercially viable and sustainable leather products that meet the demands of eco-conscious consumers, positioning TATA International as a leader in sustainable leather offerings",
        },
        {
          title: "Outreach and Education",
          text: "Our joint efforts will extend beyond the studio to raise awareness about sustainable practices in the leather industry, promoting sustainability education in the community and industry",
        },
      ],
      paragraph: null,
    },
  },
];
