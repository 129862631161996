export const blogs = [
  {
    value: 5,
    title: "Digital Twins",
    img: "https://images.idgesg.net/images/article/2018/06/digital-twins_woman-in-profile_ai_mirror_duplicate_duo_pair-100760562-large.jpg?auto=webp&quality=85,70",
    auther: "Arcticturn Foundation",
    link: "Digital-Twins",
    date: "June 1,  2023 ,  June 5th -V2",
    introduction:
      "The manufacturing sector plays a pivotal role in global economic growth and is increasingly aware of the need for sustainable practices. To enhance efficiency, reduce waste, and promote sustainable manufacturing, we propose the development of a cutting-edge digital twin solution. This research proposal outlines the objectives, methodology, timeline, and financial requirements for successfully implementing this transformative and sustainable project.",
    list1: {
      title: "Objectives",
      subtext: "The primary objectives of this research project are as follows",
      list: [
        "Develop a comprehensive digital twin framework for the manufacturing sector that integrates sustainability considerations.",
        "Enhance manufacturing process efficiency, reduce resource consumption, and minimize waste generation.",
        "Enable predictive maintenance, minimize equipment failure and optimize energy usage through real-time monitoring and data analytics.",
        "Enable virtual simulations and testing to optimize production lines, reduce material waste, and improve resource allocation.",
        "Foster innovation and collaboration in sustainable manufacturing practices by providing a platform for knowledge sharing among manufacturers, suppliers, and researchers.",
      ],
      paragraph: null,
    },

    list2: {
      title: "Methodology",
      subtext:
        "The primary objectives of this research project are as follows:",
      list: [
        {
          title: "Data Collection",
          text: "Gather relevant data from manufacturing processes, equipment, including environmental indicators such as energy consumption, waste generation, emissions and systems to build an accurate digital representation.",
        },
        {
          title: "Model Development",
          text: "Develop a robust and scalable digital twin model that mimics the real manufacturing environment, incorporating key parameters and variables considering sustainability factors, such as energy efficiency, material optimization, and environmental impact.",
        },
        {
          title: "Integration",
          text: "Connect the digital twin model to the physical manufacturing systems, allowing for real-time data exchange, monitoring, and control of sustainability-related parameters.",
        },
        {
          title: "Analytics and Optimization",
          text: "Utilize advanced analytics techniques, such as machine learning and artificial intelligence, to analyze data, identify sustainable practices, and optimize manufacturing processes to reduce environmental impact.",
        },
        {
          title: "Visualization and Collaboration",
          text: "Implement a user-friendly interface for visualizing the digital twin, facilitating collaboration, and enabling decision-making.",
        },
      ],
      paragraph: null,
    },

    list3: {
      title: "Sustainability Parameters in Digital Twin",
      subtext: null,
      list: [
        {
          title: "Energy consumption",
          text: "Measure and optimize energy usage across manufacturing operations, including electricity, heating, and cooling systems",
        },
        {
          title: "Material Efficiency",
          text: "Track the efficient use of raw materials, reducing waste and optimizing material consumption.",
        },
        {
          title: "Water Usage",
          text: "Monitor and minimize water consumption in manufacturing processes, including recycling and reuse strategies.",
        },
        {
          title: "Waste Generation",
          text: "Measure and minimize waste generation, focusing on reducing landfill waste and promoting recycling and circular economy practices.",
        },
        {
          title: "Emission and Air Quality",
          text: "Monitor and reduce emissions, including greenhouse gases, volatile organic compounds (VOCs), and particulate matter, to improve air quality and minimize environmental impact.",
        },
        {
          title: "Carbon Footprint",
          text: "Assess and reduce the overall carbon footprint of manufacturing processes, including the entire supply chain.",
        },
        {
          title: "Chemical Waste management",
          text: "Promote the safe handling and responsible use of chemicals, reducing their environmental impact and ensuring compliance with regulations.",
        },
        {
          title: "Product Life cycle Analysis",
          text: "Conduct life cycle assessments to understand and improve the environmental impact of products from raw material extraction to disposal.",
        },
        {
          title: "Renewable energy Integration",
          text: "Explore and optimize the integration of renewable energy sources, such as solar or wind power, into manufacturing operations.",
        },
        {
          title: "Sustainable Supply Chain",
          text: "Collaborate with suppliers to ensure sustainable sourcing practices, including responsible procurement, ethical labor practices, and reduced transportation emissions.",
        },
        {
          title: "Worker Health and Safety",
          text: "Implement measures to prioritize worker health and safety within manufacturing facilities, reducing accidents and promoting a healthy work environment.",
        },
        {
          title: "Social Impact",
          text: "Consider social sustainability factors such as diversity, equity, and inclusion within the manufacturing sector, promoting fair labor practices and community engagement.",
        },
      ],
      paragraph:
        "These sustainability parameters can be integrated into the digital twin model, enabling real-time monitoring, analysis, and optimization to achieve sustainable manufacturing practices. It is important to customize and refine this list based on the specific needs and priorities of the manufacturing sector being addressed.",
    },
    header3: "Sustainability Parameters in Digital Twin",
    header4: "Timeline",
    conclusion:
      "The proposed research project aims to revolutionize the manufacturing sector by developing a state-of-the-art digital twin solution. By leveraging real-time data, advanced analytics, and simulation capabilities, this project has the potential to significantly enhance efficiency, reduce downtime, and foster innovation in manufacturing processes. We seek funding support to make this project a reality, and we are confident that it will deliver tangible benefits to the manufacturing industry as a whole.",
  },

  {
    value: 1,
    title: "Professor of Practice (PoP)",
    img: "https://images.theconversation.com/files/168978/original/file-20170511-32618-121i8zo.jpg?ixlib=rb-1.1.0&q=45&auto=format&w=926&fit=clip",
    auther: "ArcticTurn Foundation",
    link: "Professor-of-Practice",
    introduction:
      "ArcticTurn Foundation is dedicated to bridging the gap between academia and industry, recognizing the importance of practical knowledge and real-world experience in driving sustainable solutions. In line with our commitment to fostering innovation and promoting sustainable practices, we are excited to introduce our new initiative, the Professor of Practice program. This blog post provides an overview of the program's objectives, benefits, and how it aims to create a valuable bridge between academia and industry.",
    list1: {
      title: "Objectives",
      subtext:
        "The Professor of Practice program aims to achieve the following objectives:",
      list: [
        "Leverage the expertise of industry professionals to enhance academic programs and curriculum.",
        "Bridge the gap between theory and practice by bringing industry knowledge and experience to the classroom.",
        "Foster collaboration and knowledge exchange between academia and industry.",
        "Equip students with practical skills and insights that align with industry needs and trends.",
        "Promote innovation and entrepreneurship by connecting students with industry mentors and opportunities.",
      ],
      paragraph: null,
    },
    list2: {
      title: "Methodology",
      subtext:
        "To achieve these objectives, the Professor of Practice program will follow a structured approach:",
      list: [
        {
          title: "Industry Engagement",
          text: "Engage with leading industry professionals and experts to identify areas of collaboration and define program focus.",
        },
        {
          title: "Curriculum Enhancement",
          text: "Integrate industry perspectives, case studies, and real-world projects into academic programs to provide students with practical knowledge and skills.",
        },
        {
          title: "Guest Lectures and Workshops",
          text: "Organize guest lectures and workshops by industry professionals to share their experiences, insights, and expertise with students and faculty.",
        },
        {
          title: "Mentorship and Internships",
          text: "Facilitate mentorship programs and industry internships to provide students with hands-on experience and exposure to real-world challenges.",
        },
        {
          title: "Collaborative Research",
          text: "Promote collaborative research projects between academia and industry, fostering innovation and addressing sustainability challenges.",
        },
      ],
      paragraph: null,
    },
    conclusion:
      "The Professor of Practice program at ArcticTurn Foundation is a vital initiative that bridges the gap between academia and industry. By integrating industry professionals into academic settings, we aim to provide students with practical knowledge, enhance their employability, and foster innovation and collaboration. We invite industry professionals and educational institutions to join us in this transformative program and contribute to shaping the next generation of sustainable leaders.",
  },

  {
    value: 3,
    title: "University Collaboration",
    img: "https://images.unsplash.com/photo-1605470207062-b72b5cbe2a87?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dWslMjB1bml2ZXJzaXR5fGVufDB8fDB8fHww&w=1000&q=80",
    auther: "Arcticturn Foundation",
    link: "University-Collaboration",
    introduction:
      "ArcticTurn Foundation is dedicated to promoting sustainable practices and fostering innovation across various sectors. In our ongoing commitment to driving positive change, we are thrilled to introduce our initiative focused on University Collaboration. This blog post outlines the objectives, strategies, and anticipated outcomes of this endeavor, aimed at facilitating partnerships and collaboration among universities worldwide.",
    list1: {
      title: "Objectives",
      subtext:
        "The primary objectives of the University Collaboration initiative are as follows:",
      list: [
        "Facilitate collaboration between ArcticTurn Foundation and universities to drive sustainable innovation.",
        "Promote knowledge sharing and interdisciplinary research on sustainability challenges.",
        "Enable joint research projects and initiatives to address pressing environmental and social issues.",
        "Enhance academic excellence through the integration of sustainability principles across university curricula.",
        "Support student engagement and experiential learning opportunities in sustainable innovation.",
      ],
      paragraph: null,
    },
    list2: {
      title: "Methodology",
      subtext:
        "To achieve these objectives, the University Collaboration initiative will follow a structured approach:",
      list: [
        {
          title: "Partnership Development",
          text: "ArcticTurn Foundation will establish partnerships with universities worldwide, fostering strong relationships and collaborative networks.",
        },
        {
          title: "Joint Research Projects",
          text: "ArcticTurn Foundation and partner universities will collaborate on interdisciplinary research projects to address sustainability challenges.",
        },
        {
          title: "Curriculum Integration",
          text: "ArcticTurn Foundation will work with universities to integrate sustainability principles into existing curricula, promoting interdisciplinary learning and sustainability-focused education.",
        },
        {
          title: "Student Engagement",
          text: "The initiative will provide students with opportunities for hands-on experience and engagement in sustainable innovation projects, fostering their skills and knowledge.",
        },
        {
          title: "Knowledge Sharing",
          text: "ArcticTurn Foundation and partner universities will organize seminars, workshops, and conferences to facilitate knowledge sharing and collaboration among researchers, faculty, and students.",
        },
      ],
      paragraph: null,
    },
    conclusion:
      "ArcticTurn Foundation is committed to building strong partnerships with universities and driving sustainable innovation through collaboration. The University Collaboration initiative aims to harness the collective expertise and resources of ArcticTurn Foundation and partner universities to create a lasting impact on sustainability. We invite universities, researchers, faculty, and students to join us in this journey towards a more sustainable and innovative future.",
  },

  {
    value: 4,
    title: "Researchers & Student Mobility",
    img: "https://www.libertytravel.com/sites/default/files/styles/full_size/public/student%20travel-m.jpg?itok=AlcoKJXG",
    auther: "Arcticturn Foundation",
    link: "Researchers-&-Student-Mobility",
    introduction:
      "ArcticTurn Foundation recognizes the importance of promoting knowledge exchange, collaboration, and mobility among researchers and students. In our continuous efforts to support sustainable practices and innovation, we are excited to announce our new initiative focused on enhancing Researchers & Student Mobility. This blog post outlines the objectives, methodology, and expected outcomes of this initiative, aimed at fostering a global network of researchers and students in various fields.",
    list1: {
      title: "Objectives",
      subtext: "The primary objectives of this research project are as follows",
      list: [
        "Facilitate collaboration and knowledge sharing among researchers and students across geographical boundaries.",
        "Promote cultural diversity, cross-disciplinary interactions, and innovation through international mobility programs.",
        "Enhance research capabilities and academic excellence through global partnerships and exchange programs.",
        "Enable virtual simulations and testing to optimize production lines, reduce material waste, and improve resource allocation.",
        "Support skill development, professional growth, and exposure to diverse research methodologies and perspectives.",
      ],
      paragraph: null,
    },

    list2: {
      title: "Methodology",
      subtext:
        "To achieve these objectives, the Researchers & Student Mobility initiative will follow a structured approach:",
      list: [
        {
          title: "Establishing Partnerships",
          text: "We will collaborate with renowned academic institutions, research organizations, and industry partners worldwide to create a robust network for research and student exchange.",
        },
        {
          title: "Mobility Programs",
          text: "We will design and implement various mobility programs, such as research internships, study abroad opportunities, joint research projects, and academic conferences. These programs will enable researchers and students to engage in collaborative research, gain international exposure, and broaden their horizons.",
        },
        {
          title: "Scholarship Opportunities:",
          text: "We will provide scholarships and funding support to deserving researchers and students, ensuring that financial constraints do not hinder their mobility and learning experiences",
        },
        {
          title: "Mentorship and Guidance",
          text: "Experienced researchers and professionals will mentor and guide participants throughout their mobility journeys, offering valuable insights, feedback, and support.",
        },
        {
          title: "Monitoring and Evaluation",
          text: "We will establish mechanisms to monitor and evaluate the impact of the mobility programs, ensuring continuous improvement and alignment with the initiative's goals.",
        },
      ],
      paragraph: null,
    },
    conclusion:
      "ArcticTurn Foundation, we are committed to nurturing talent, fostering collaboration, and driving positive change. The Researchers & Student Mobility initiative is a testament to our dedication to creating a global platform for researchers and students to connect, learn, and contribute to society. We invite researchers, students, and institutions to join us on this transformative journey towards a more interconnected and sustainable world.",
  },
];
