import React from "react";
import Blogcard from "../component/Blogcard";
import University from "../../Images/University.jpg";
import { blogs } from "../utils/BlogsList";
import { Link } from "react-router-dom";
import SmallLogo from "../../Images/smallLogo.png";
import LinkedinLogo from "../../Images/linked in .png";
import Group_photos from "../../Images/unlv group photo copy.jpeg";
import { linkedinposts } from "../utils/linkedinposts";

const Linkedincard = (post) => {
  return (
    <div class="linkedin-card">
      <div class="linkedin-card-header">
        <img src={SmallLogo} alt="Profile Picture" class="profile-picture" />
        <div class="post-info">
          <h3 class="author-name">Arcticturn Foundation</h3>
          <p class="post-meta">
            Admin • Posted on <span class="post-date">{post.post.date}</span>
          </p>
        </div>
      </div>
      <div class="linkedin-card-body">
        <h1 class="post-headline">{post.post.headline}</h1>
        <br />
        <p class="post-content">{post.post.content}</p>
        <div class="post-image">
          <img src={post.post.img} alt="Post Image" />
        </div>
      </div>
      <div class="linkedin-card-footer">
        <a href={post.post.postlink} target="_blank" class="linkedin-link" rel="noreferrer">
          View on LinkedIn
        </a>
      </div>
    </div>
  );
};

export default Linkedincard;
