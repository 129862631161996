import React from "react";

const Blogimage = (props) => {
  return (
    <div className="blogimage_container">
      <img className="blogimage" src={props.text} />
    </div>
  );
};

export default Blogimage;
