import React, { useState } from "react";
import Researchers from "../../Images/researchers.png";
import Institutions from "../../Images/institutions.png";
import Industries from "../../Images/industry.png";

const Collaboration = (props) => {
  // const [partner, setPartner] = useState({
  //   partner1: null,
  //   partner2: null,
  //   partner3: null,
  // });

  // const partnerFunction = (obj) => {
  //   setPartner(obj);
  //   alert(partner);
  // };

  return (
    <div className="collaboration">
      <div className="collaboration__contentsection">
        <div className="collaboration__contentsection__head">
          <div className="collaboration__contentsection__head_title">
            ArcticTurn Foundation
          </div>
          <div className="collaboration__contentsection__head_header">
            Partners with
          </div>{" "}
        </div>
        <div className="collaboration__contentsection_subtitle">
          <div className="collaboration__contentsection_subtitle_partner">
            <div className="collaboration__contentsection_subtitle_partner_top">
              <img
                src={Institutions}
                className="collaboration__contentsection_subtitle_partner_top_img"
              />
              Academia
            </div>
            <div className="collaboration__contentsection_subtitle_partner_bottom">
              <p className="collaboration__contentsection_subtitle_partner_bottom_points">
                Bridging the gap between theory and practice
              </p>
              <p className="collaboration__contentsection_subtitle_partner_bottom_points">
                By fostering collaborations with academic and educational
                organizations, we aim to equip future leaders with the knowledge
                and tools to address sustainability challenges effectively.
              </p>
            </div>
          </div>
          <div className="collaboration__contentsection_subtitle_partner">
            <div className="collaboration__contentsection_subtitle_partner_top">
              <img
                src={Industries}
                className="collaboration__contentsection_subtitle_partner_top_img"
              />
              Industry
            </div>
            {/* {partner.partner1 && ( */}
            <div className="collaboration__contentsection_subtitle_partner_bottom">
              <p className="collaboration__contentsection_subtitle_partner_bottom_points">
                We provide guidance, resources, and expertise to help businesses
                integrate sustainable practices into their operations.
              </p>
              <p className="collaboration__contentsection_subtitle_partner_bottom_points">
                Together, we strive to build a sustainable business ecosystem{" "}
              </p>
            </div>
          </div>

          <div className="collaboration__contentsection_subtitle_partner">
            <div className="collaboration__contentsection_subtitle_partner_top">
              <img
                src={Researchers}
                className="collaboration__contentsection_subtitle_partner_top_img"
              />
              Research
            </div>
            {/* {partner.partner1 && ( */}
            <div className="collaboration__contentsection_subtitle_partner_bottom">
              <p className="collaboration__contentsection_subtitle_partner_bottom_points">
                At ATF, we offer a platform for active researchers to work on
                live projects and gain cutting-edge insights, tools, and
                methods.
              </p>
              <p className="collaboration__contentsection_subtitle_partner_bottom_points">
                Collaboration with ATF will drive impactful research and
                generate solutions for a sustainable future.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="imgsection">
        <div className="circleAnimation1"></div>
        <div className="circleAnimation3"></div>
        <div className="circleAnimation2"></div>
        <div className="circleAnimation4"></div>
      </div>
    </div>
  );
};

export default Collaboration;
